/* eslint-disable @typescript-eslint/member-ordering */
import {
    AnyPcbBakedNode,
    AnyPcbNode,
    AsyncClipperShapeFactory,
    CommandType,
    FeatureFlagValues,
    IFillNodesGoods,
    ISpillNodesGoods,
    IVirtualNodesGoodsFromRules,
    MissingChildUidError,
    PcbBakedNode,
    PcbDocumentState,
    PcbLayoutEngine,
    PcbNodeHelper,
    PcbNodeTypes,
    PcbNodesMap,
    PcbRuleSetsMap,
    PcbPrimitiveStore,
    PcbTracingGraph,
    getFirstTopLevelContainerNode,
    pcbLayoutRootNodeId,
} from "@buildwithflux/core";
import {Command, getSubTreeUids} from "@buildwithflux/models";
import {Logger, areWeTestingWithJest} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import {Remote} from "comlink";
import {assign, chain, isEmpty, keyBy, merge, pick, pickBy, values} from "lodash";

import {ReduxStoreService} from "../../../../redux/util/service";
import {PcbConnectivityGraph} from "../../../connectivity/PcbConnectivityGraph";
import kicadFootprintToFluxFootprint from "../../../data_portability/importers/kicad/footprints/KicadToFlux";
import {FeatureFlagsStore} from "../../../feature_flags/FeatureFlagsStore";
import {createPcbLayoutEngineWorker} from "../../../pcb_layout_engine/pcbLayoutEngineInMain";
import {FluxLogger} from "../../../storage_engine/connectors/LogConnector";
import {usePcbEditorUiStore} from "../PcbEditorUiStore";
import {usePcbLayerViewStore} from "../PcbLayerViewStore";

import {DrcManager} from "./DrcManager";
import {createBoundedQueue} from "./createBoundedQueue";

/**
 * This class's service instance should be used from the main thread for baking nodes and storing the results, cleverly
 * called "baked goods". It abstracts away some lower level details:
 *   1. setting nodes in document service
 *   2. setting virtual rules in document service
 *   3. setting shapes in the shapes store (NOTE: upcoming in https://github.com/buildwithflux/flux-app/pull/4601)
 *   4. triggering DRC recalculation with all changes
 */
export class PcbBakedGoodsManager {
    /**
     * Enqueues a DRC processing task and manages the queue. Management involves executing
     * tasks in the queue sequentially as well as limiting the number of tasks that can be
     * enqueued at a given time.
     *
     * The queuing mechanism only allows two tasks to be enqueued at a given time - the
     * task currently being processed, and a subsequent task. This is to ensure that the
     * DRC processing remains more responsive, avoiding intermediary tasks that are
     * redundant when newer tasks are enqueued.
     */
    private boundedQueueDrc = createBoundedQueue();
    private documentServiceCommandBatch: Array<Command> = [];

    constructor(
        private readonly documentService: DocumentService,
        private readonly pcbPrimitiveStore: PcbPrimitiveStore,
        private readonly pcbConnectivityGraph: PcbConnectivityGraph,
        private readonly pcbTracingGraph: PcbTracingGraph,
        private readonly reduxStoreService: ReduxStoreService,
        private readonly featureFlagsStore: FeatureFlagsStore,
        protected readonly logger: Logger,
    ) {}

    public async reload(
        documentState: PcbDocumentState,
        treeNodes: PcbNodesMap<AnyPcbNode>,
        pcbLayoutRules: PcbRuleSetsMap,
    ) {
        await this.bakeAllAndAdd(documentState, treeNodes, pcbLayoutRules, true);
    }

    /**
     * To be called when loading a document for the first time. It also inits
     * the PcbLayerViewStore which depends on the active layout node stackup.
     */
    public async firstLoad(
        documentState: PcbDocumentState,
        treeNodes: PcbNodesMap<AnyPcbNode>,
        pcbLayoutRules: PcbRuleSetsMap,
    ) {
        // NOTE: top level layout could be deleted
        const activeNode = getFirstTopLevelContainerNode(documentState.pcbLayoutNodes || {});

        usePcbLayerViewStore
            .getState()
            .initLayerVisibilityMaps(documentState.layerViewConfig, activeNode, documentState.uid);

        await this.bakeAllAndAdd(documentState, treeNodes, pcbLayoutRules, false);

        if (activeNode) {
            const activeBakedNode = this.getAllNodes()[activeNode.uid] as
                | PcbBakedNode<PcbNodeTypes.layout>
                | PcbBakedNode<PcbNodeTypes.footprint>;
            usePcbLayerViewStore.getState().updateLayerVisibilityMap(activeBakedNode);
        }
    }

    /**
     * Clear all nodes in documentService
     */
    public clear() {
        this.documentService.clear();
        this.pcbPrimitiveStore.clear();
        this.pcbConnectivityGraph.clear();
        this.pcbTracingGraph.clear();
    }

    public async removeNodesAndUpdateFills(
        documentState: PcbDocumentState,
        pcbLayoutNodes: PcbNodesMap<AnyPcbNode>,
        pcbLayoutRuleSets: PcbRuleSetsMap,
        nodesToRemove: AnyPcbNode[],
    ) {
        this.documentService.setDataAsStale(true);

        // Initialize the DRC recalculation
        // DRC is finally recalculated at the end of this method once all changes have been collected
        const drc = new DrcManager(
            this.pcbPrimitiveStore,
            this.pcbConnectivityGraph,
            this.pcbTracingGraph,
            this.reduxStoreService,
            this.documentService,
            this.featureFlagsStore,
            this.logger,
        );

        const pcbLayoutEngine = await this.getPcbLayoutEngine(documentState, pcbLayoutNodes, pcbLayoutRuleSets);

        this.removeNodes(nodesToRemove, drc);

        const virtualSpillNodes = await pcbLayoutEngine.generateSpillNodes();

        {
            const virtualFillNodes = await pcbLayoutEngine.generateFillNodes(virtualSpillNodes.shapesMap);

            // NOTE: update shapesMap before updating any baked nodes, this is important for downstream
            // modules such as DRC. See diagram and description in PR: https://github.com/buildwithflux/flux-app/pull/8734
            this.setShapesMap(virtualFillNodes, virtualSpillNodes);

            /* Finally handle virtualFillNodes */ //
            this.updateVirtualNodes(virtualFillNodes, drc, virtualSpillNodes);
        }

        this.processCommandBatch();

        this.documentService.setDataAsStale(false);

        this.boundedQueueDrc.enqueue(await drc.recalculate());
    }

    /**
     * Pass information from redux, bake rules for the node and set the baked node in document service
     *
     * @param documentState - document state from redux
     * @param pcbLayoutNodes - pcbLayoutNodes from redux
     * @param pcbLayoutRuleSets - pcbLayoutRuleSets from redux
     * @param nodes - the nodes we will update and bake
     * @param {boolean} hasInheritedRules - whether or not this update involves inherited rules
     * @param {boolean} hasInheritedCalculations - whether or not this update involves inherited calculations
     * @param {boolean} requiresDescendantRebake - whether or not this update requires descendant rebake
     * @param removeNodes - any nodes we will remove before baking
     */
    public async bakeNodesAndUpdate(
        documentState: PcbDocumentState,
        pcbLayoutNodes: PcbNodesMap<AnyPcbNode>,
        pcbLayoutRuleSets: PcbRuleSetsMap,
        nodes: AnyPcbNode[],
        hasInheritedRules: boolean,
        hasInheritedCalculations: boolean,
        requiresDescendantRebake: boolean,
        removeNodes?: AnyPcbNode[],
    ) {
        this.documentService.setDataAsStale(true);

        // Initialize the DRC recalculation
        // DRC is finally recalculated at the end of this method once all changes have been collected
        const drc = new DrcManager(
            this.pcbPrimitiveStore,
            this.pcbConnectivityGraph,
            this.pcbTracingGraph,
            this.reduxStoreService,
            this.documentService,
            this.featureFlagsStore,
            this.logger,
        );

        let resetFillsCache = false;

        const pcbLayoutEngine = await this.getPcbLayoutEngine(documentState, pcbLayoutNodes, pcbLayoutRuleSets);

        if (removeNodes) {
            this.removeNodes(removeNodes, drc);
            resetFillsCache = true;
        }

        const {virtualNodesGoodsFromRules, pcbLayoutNodes: nodesToUpdate} = await pcbLayoutEngine.rebakeNodes(
            nodes.map((node) => node.uid),
            hasInheritedRules,
            hasInheritedCalculations,
            requiresDescendantRebake,
        );

        /* First, handle virtual nodes/rules from rules */
        if (virtualNodesGoodsFromRules) {
            resetFillsCache = resetFillsCache || Boolean(this.cleanUpVirtualNodes(virtualNodesGoodsFromRules, drc));
            this.updateRuleSets(virtualNodesGoodsFromRules, drc);
        }

        resetFillsCache = resetFillsCache || this.shouldResetFillsCache(nodesToUpdate);

        this.updateNodes(values(nodesToUpdate), drc);

        const virtualSpillNodes = await pcbLayoutEngine.generateSpillNodes();
        merge(
            nodesToUpdate,
            keyBy([...virtualSpillNodes.nodesToAdd, ...virtualSpillNodes.nodesToUpdate], (node) => node.uid),
        );

        if (Object.values(nodesToUpdate).some((node) => node.type === PcbNodeTypes.polygon)) {
            // A new polygon requires cleaning up the fills cache
            resetFillsCache = true;
        }

        {
            const virtualFillNodes = await pcbLayoutEngine.generateFillNodes(
                virtualSpillNodes.shapesMap,
                resetFillsCache ? undefined : nodesToUpdate,
            );

            // NOTE: update shapesMap before updating any baked nodes, this is important for downstream
            // modules such as DRC. See diagram and description in PR: https://github.com/buildwithflux/flux-app/pull/8734
            this.setShapesMap(virtualFillNodes, virtualSpillNodes);

            /* Finally handle virtualFillNodes */ //
            this.updateVirtualNodes(virtualFillNodes, drc, virtualSpillNodes);
        }

        // Update smart via configs cache if any smart via nodes are updated
        if (values(nodesToUpdate).some((node) => node.type === PcbNodeTypes.smartVia)) {
            await this.updateSmartViaConfigsCache(pcbLayoutEngine);
        }

        this.processCommandBatch();

        this.documentService.setDataAsStale(false);

        this.boundedQueueDrc.enqueue(await drc.recalculate());
    }

    /**
     * Pass information from redux, bake rules for the node and set the baked node in document service
     *
     * @param documentState - document state from redux
     * @param pcbLayoutNodes - pcbLayoutNodes from redux
     * @param pcbLayoutRuleSets - pcbLayoutRuleSets from redux
     * @param nodes - the nodes we will update and bake
     * @param removeNodes - any nodes we will remove before baking
     */
    public async bakeNodesAndAdd(
        documentState: PcbDocumentState,
        pcbLayoutNodes: PcbNodesMap<AnyPcbNode>,
        pcbLayoutRuleSets: PcbRuleSetsMap,
        nodes: AnyPcbNode[],
        removeNodes?: AnyPcbNode[],
    ) {
        this.documentService.setDataAsStale(true);

        // Initialize the DRC recalculation
        // DRC is finally recalculated at the end of this method once all changes have been collected
        const drc = new DrcManager(
            this.pcbPrimitiveStore,
            this.pcbConnectivityGraph,
            this.pcbTracingGraph,
            this.reduxStoreService,
            this.documentService,
            this.featureFlagsStore,
            this.logger,
        );

        let resetFillsCache = false;

        const pcbLayoutEngine = await this.getPcbLayoutEngine(documentState, pcbLayoutNodes, pcbLayoutRuleSets);

        if (removeNodes) {
            this.removeNodes(removeNodes, drc);
            resetFillsCache = true;
        }

        // Prevent supplying virtual nodes for baking. They should instead be a product of the baking process
        nodes = nodes.filter((node) => !PcbNodeHelper.isVirtualNode(node));

        const {virtualNodesGoodsFromRules, pcbLayoutNodes: nodesToAdd} = await pcbLayoutEngine.bakeNodes(nodes);

        /* First, handle virtual nodes/rules from rules */
        if (virtualNodesGoodsFromRules) {
            resetFillsCache = resetFillsCache || Boolean(this.cleanUpVirtualNodes(virtualNodesGoodsFromRules, drc));
            this.updateRuleSets(virtualNodesGoodsFromRules, drc);
        }

        if (Object.values(nodesToAdd).some((node) => node.type === PcbNodeTypes.polygon)) {
            // A new polygon requires cleaning up the fills cache
            resetFillsCache = true;
        }

        const virtualSpillNodes = await pcbLayoutEngine.generateSpillNodes();
        const changedSpillNodes = virtualSpillNodes.nodesToAdd.concat(virtualSpillNodes.nodesToUpdate);

        merge(
            nodesToAdd,
            keyBy(changedSpillNodes, (node) => node.uid),
        );

        // Now `pcbLayoutNodes` contains the new baked nodes, add them in document service
        const nodesToAddArray = values(nodesToAdd);
        // The problem .. we're getting a PCB re-render mid call to bakNodesAndAdd(..), causing a re-render before primitives exist
        this.addNodes(nodesToAddArray, drc);

        {
            const virtualFillNodes = await pcbLayoutEngine.generateFillNodes(
                virtualSpillNodes.shapesMap,
                resetFillsCache ? undefined : nodesToAdd,
            );

            // NOTE: update shapesMap before updating any baked nodes, this is important for downstream
            // modules such as DRC. See diagram and description in PR: https://github.com/buildwithflux/flux-app/pull/8734
            this.setShapesMap(virtualFillNodes, virtualSpillNodes);

            /* Finally handle virtualFillNodes */ //
            this.updateVirtualNodes(virtualFillNodes, drc, virtualSpillNodes);
        }

        // Update smart via configs cache if any smart via nodes are added
        if (values(nodesToAdd).some((node) => node.type === PcbNodeTypes.smartVia)) {
            await this.updateSmartViaConfigsCache(pcbLayoutEngine);
        }

        this.processCommandBatch();

        this.documentService.setDataAsStale(false);

        this.boundedQueueDrc.enqueue(await drc.recalculate());
    }

    /**
     * Dispatches all collected document commands to document service, and then clears the collection of commands
     */
    private processCommandBatch() {
        for (const command of this.documentServiceCommandBatch) {
            this.documentService.applyCommand(command);
        }
        this.documentServiceCommandBatch = [];
    }

    /**
     * There are many scenarios that we need to access all nodes with all
     * information. This means we want to access all virtual + non-virtual
     * nodes, AND each node contains its latest baked rules. Here we are
     * exposing such an API
     *
     * Please note this is different than `pcbLayoutNodes` in redux in two ways:
     * 1. This includes both non-virtual and virtual nodes
     * 2. Each node contains `bakedRules`
     *
     * @returns {PcbNodesMap<AnyPcbBakedNode>} all nodes we have, including both non-virutal and virtual nodes.
     */
    private getAllNodes(): Readonly<PcbNodesMap<AnyPcbBakedNode>> {
        return this.documentService.snapshot().pcbLayoutNodes;
    }

    /**
     * Bakes all nodes (from redux), starting from the top level ones, and traverse the tree
     * in pre-order
     *
     * @param {PcbDocumentState} documentState
     * @param {PcbNodesMap<AnyPcbNode>} treeNodes
     * @param {PcbRuleSetsMap} pcbLayoutRules
     * @param clearEverything
     */
    private async bakeAllAndAdd(
        documentState: PcbDocumentState,
        treeNodes: PcbNodesMap<AnyPcbNode>,
        pcbLayoutRules: PcbRuleSetsMap,
        clearEverything?: boolean,
    ) {
        this.documentService.setDataAsStale(true);

        // Initialize the DRC recalculation
        // DRC is finally recalculated at the end of this method once all changes have been collected
        const drc = new DrcManager(
            this.pcbPrimitiveStore,
            this.pcbConnectivityGraph,
            this.pcbTracingGraph,
            this.reduxStoreService,
            this.documentService,
            this.featureFlagsStore,
            this.logger,
        );

        const pcbLayoutEngine = await this.getPcbLayoutEngine(documentState, treeNodes, pcbLayoutRules);

        const {pcbLayoutNodes, virtualNodesGoodsFromRules, virtualFillNodes, virtualSpillNodes} =
            await pcbLayoutEngine.bakeAllNodes();

        if (clearEverything) this.clear();

        // NOTE: update shapesMap before updating any baked nodes, this is important for downstream
        // modules such as DRC. See diagram and description in PR: https://github.com/buildwithflux/flux-app/pull/8734
        this.setShapesMap(virtualFillNodes, virtualSpillNodes);

        const rootNode = pcbLayoutNodes[pcbLayoutRootNodeId];
        if (!rootNode) {
            throw new Error("Root node not found");
        }
        this.addSubTreesToDocumentService(
            [rootNode],
            pcbLayoutNodes,
            drc,
            // Previously we used `pcbLayoutEngine.treeTraveler` property in order to reuse the
            // TreeTraveler cache. However, after moving the `PcbLayoutEngine` to a web worker, the
            // cache now lives inside the worker and we can't access it.
            // Example: 817 PCB nodes take around 3ms to be cached again. At the time of the
            // testing, this was 0.002% of the total time to bake the PCB and add them to the
            // document service.
        );

        if (virtualNodesGoodsFromRules) {
            this.updateRuleSets(virtualNodesGoodsFromRules, drc);
        }

        await this.updateSmartViaConfigsCache(pcbLayoutEngine);

        this.updateVirtualNodes(virtualFillNodes, drc, virtualSpillNodes);
        this.cleanUpVirtualNodes(virtualNodesGoodsFromRules, drc);

        this.processCommandBatch();

        this.documentService.setDataAsStale(false);

        this.boundedQueueDrc.enqueue(await drc.recalculate());
    }

    /**
     * Adds a sub tree to document service.
     *
     * This is method is usually useful when
     * 1. First time loading the project
     * 2. Anytime we need to re-load the project, such as updating GlobalRuleSets
     * 3. Adding virtual nodes
     * 4. Back-filling nodes
     *
     * @param {AnyPcbBakedNode[]} subTreeRootNodes - root nodes of the subTree
     * @param {PcbNodesMap<AnyPcbNode>} allNodes - nodes map containing the whole sub tree
     * @return {void}
     */
    private addSubTreesToDocumentService(
        subTreeRootNodes: AnyPcbBakedNode[],
        allNodes: PcbNodesMap<AnyPcbBakedNode>,
        drc: DrcManager,
    ): void {
        let nodesToAdd: any[] = [];
        // Get all descendants recursively
        subTreeRootNodes.forEach((subTreeRootNode) => {
            nodesToAdd = nodesToAdd.concat([...this.yieldSubTreeRecursive(subTreeRootNode, allNodes)]);
        });
        // Finally add the root nodes and their descendants altogether
        this.addNodes([...subTreeRootNodes, ...nodesToAdd.flat()], drc, true);
    }

    private *yieldSubTreeRecursive(
        subTreeRootNode: AnyPcbBakedNode,
        allNodes: PcbNodesMap<AnyPcbBakedNode>,
    ): Generator<AnyPcbBakedNode[]> {
        const childUids = subTreeRootNode.childUids;

        if (childUids) {
            yield values(pick(allNodes, childUids));
            for (const childUid of childUids) {
                const childNode = allNodes[childUid];
                if (childNode) {
                    yield* this.yieldSubTreeRecursive(childNode, allNodes);
                } else {
                    FluxLogger.captureError(
                        new MissingChildUidError(`node ${subTreeRootNode.uid} with type ${subTreeRootNode.type}`),
                    );
                }
            }
        }
    }

    /**
     * Add one or more nodes to documentService. This method creates a `setPcbNodes` operation
     * and pass new nodes as the operation's payload; then ask documentService to perform this operation
     *
     * @param {AnyPcbBakedNode[]} nodes - the nodes we want to add, they should be baked nodes
     * @param {DrcManager} drc - DRC recalculation object
     * @param {boolean} skipSort - skip topological sort if set. We only skip when reloading the whole board
     */
    private addNodes(nodes: AnyPcbBakedNode[], drc: DrcManager, skipSort?: boolean) {
        // Don't apply commands for empty node lists, to avoid triggering unnecessary notifications
        // to downstream subscribers
        if (!nodes.length) {
            return;
        }

        // Add nodes to documentService
        this.documentServiceCommandBatch.push({
            type: CommandType.enrichedAddPcbNodesCommand,
            nodes,
            skipSort,
        });

        // Add nodes for DRC recalculation
        drc.addNodesChange("add", nodes);
    }

    /**
     * Remove one or more nodes from document service.  This method creates a `removePcbNodes` operation
     * and pass new nodes as the operation's payload; then ask documentService to perform this operation
     *
     * @param {AnyPcbBakedNode[]} nodes - nodes we want to remove
     * @param {DrcManager} drc - DRC recalculation object
     */
    private removeNodes(nodes: AnyPcbNode[], drc: DrcManager) {
        // Get child nodes to delete
        nodes = nodes
            .filter(Boolean) // only try to delete nodes that exist
            .map((node) => getSubTreeUids({pcbNodes: this.getAllNodes(), nodeUid: node.uid, includeRoot: true}))
            .flat()
            .map((uid) => this.getAllNodes()[uid]!)
            .filter(Boolean); // only try to delete nodes that exist

        // Don't apply commands for empty node lists, to avoid triggering unnecessary notifications
        // to downstream subscribers
        if (!nodes.length) {
            return;
        }

        // Remove them from documentService
        const nodeUids = nodes.map((node) => node.uid);
        this.documentServiceCommandBatch.push({
            type: CommandType.removePcbNodesCommand,
            nodeUids,
        });

        // And remove any rule sets that have the same ID, which should always be
        // true for generated rules.
        const ruleSetUids = nodeUids.filter((uid) => {
            return this.documentService.snapshot().pcbLayoutRuleSets[uid];
        });
        this.documentServiceCommandBatch.push({
            type: CommandType.removePcbRuleSetsCommand,
            ruleSetUids,
        });

        // Add nodes for DRC recalculation
        drc.addNodesChange("remove", nodes);
        drc.addRuleSetsChange("remove", ruleSetUids);
    }

    /**
     * Update one or more nodes in document service.This method creates a `setPcbNodes` operation
     * and pass new nodes as the operation's payload; then ask documentService to perform this operation
     *
     * @param {AnyPcbNode[]} nodes - nodes we want to remove
     * @param {DrcManager} drc - DRC recalculation object
     */
    private updateNodes(nodes: AnyPcbBakedNode[], drc: DrcManager) {
        // Don't apply commands for empty node lists, to avoid triggering unnecessary notifications
        // to downstream subscribers
        if (!nodes.length) {
            return;
        }

        this.documentServiceCommandBatch.push({
            type: CommandType.enrichedUpdatePcbNodesCommand,
            nodes,
        });

        // Add nodes for DRC recalculation
        drc.addNodesChange("replace", nodes);
    }

    /**
     * This checks for changes in a few baked rules that effectively remove a
     * node from a fill layer and so require a reset of that fill layer's cache.
     *
     * @see fillGetKeepoutMode
     */
    private shouldResetFillsCache(nodesToUpdate: PcbNodesMap<AnyPcbBakedNode>): boolean {
        return !isEmpty(
            pickBy(nodesToUpdate, (updatedNode) => {
                const oldRules = this.documentService.snapshot().pcbLayoutNodes[updatedNode.uid]?.bakedRules;
                const updatedRules = updatedNode.bakedRules;

                const isZoneConnectedLayerChange =
                    oldRules &&
                    updatedNode.type === PcbNodeTypes.zone &&
                    ("connectedLayers" in updatedRules && updatedRules.connectedLayers?.join("-")) !==
                        ("connectedLayers" in oldRules && oldRules.connectedLayers?.join("-"));
                const isActiveChange = oldRules?.active !== updatedRules.active;
                const isLayerChange = oldRules?.layer !== updatedRules.layer;
                const isHoleTypeChange =
                    oldRules &&
                    "hole" in updatedRules &&
                    updatedRules.hole?.holeType !== ("hole" in oldRules && oldRules.hole?.holeType);
                const isHostNetIdChange =
                    oldRules &&
                    "hostNetId" in updatedRules &&
                    updatedRules.hostNetId !== ("hostNetId" in oldRules && oldRules.hostNetId);
                const isSmartViaNode = updatedNode.type === PcbNodeTypes.smartVia;

                return (
                    isZoneConnectedLayerChange ||
                    isActiveChange ||
                    isLayerChange ||
                    isHoleTypeChange ||
                    isHostNetIdChange ||
                    isSmartViaNode
                );
            }),
        );
    }

    /**
     * This exists because virtual nodes are only added when a rule is updated,
     * so we also need to remove stale virtual nodes.
     */
    private cleanUpVirtualNodes(virtualNodesGoodsFromRules: IVirtualNodesGoodsFromRules[] = [], drc: DrcManager) {
        const nodesToRemove = virtualNodesGoodsFromRules.flatMap((good) => good.nodesToRemove);
        if (nodesToRemove.length) {
            this.removeNodes(nodesToRemove, drc);
        }
        return nodesToRemove.length;
    }

    /**
     * A tedious function to remove and add any auto selector-based rules for
     * virtual nodes.
     *
     * NOTE: we assume here that the rule set IDs are the same as the IDs of the
     * nodes they apply to. See createPadBasedRulesAndMountingPads and generateVirtualNodesFromRules.
     */
    private updateRuleSets(goodsArray: IVirtualNodesGoodsFromRules[], drc: DrcManager) {
        const ruleSetsToRemove = chain(goodsArray)
            .map((goods) => goods.ruleSetsToRemove)
            .reduce((merged, ruleSetsToRemove) => assign(merged, ruleSetsToRemove))
            .toArray()
            .value();

        const ruleSetsToAdd = chain(goodsArray)
            .map((goods) => goods.ruleSetsToAdd)
            .reduce((merged, ruleSetsToAdd) => assign(merged, ruleSetsToAdd))
            .toArray()
            .value();

        this.documentServiceCommandBatch.push({
            type: CommandType.removePcbRuleSetsCommand,
            ruleSetUids: ruleSetsToRemove,
        });

        this.documentServiceCommandBatch.push({
            type: CommandType.addPcbRuleSetsCommand,
            ruleSets: ruleSetsToAdd,
        });

        drc.addRuleSetsChange("remove", ruleSetsToRemove);
        drc.addRuleSetsChange("add", ruleSetsToAdd);
    }

    private updateVirtualNodes(
        virtualFillNodes: IFillNodesGoods | undefined,
        drc: DrcManager,
        virtualSpillNodes?: ISpillNodesGoods,
    ) {
        // NOTE: order shouldn't make a difference -- the add/remove/update sets
        // are mutually exclusive.
        // TODO: Also make sure to remove child nodes
        // TODO: [NodeType] - Remove this as cast when we polish the typing for virtualFillNodes
        if (virtualFillNodes) {
            this.removeNodes(virtualFillNodes.nodesToRemove as AnyPcbBakedNode[], drc);
            this.addNodes(virtualFillNodes.nodesToAdd as AnyPcbBakedNode[], drc, false);
            this.updateNodes(virtualFillNodes.nodesToUpdate as AnyPcbBakedNode[], drc);
        }
        if (virtualSpillNodes) {
            this.removeNodes(virtualSpillNodes.nodesToRemove as AnyPcbBakedNode[], drc);
            this.addNodes(virtualSpillNodes.nodesToAdd as AnyPcbBakedNode[], drc, false);
            this.updateNodes(virtualSpillNodes.nodesToUpdate as AnyPcbBakedNode[], drc);
        }
    }

    private setShapesMap(
        virtualFillNodes: IFillNodesGoods | undefined,
        virtualSpillNodes: ISpillNodesGoods | undefined,
    ) {
        // NOTE: call `setShapesMap` first because some downstream modules such as
        // DRC subscribes to changes to baked nodes, and by then we want to make sure
        // `shapesMap` is up-to-date in order to calculate connectivity graph correctly
        if (virtualFillNodes) {
            const fills = virtualFillNodes.shapesMap;
            this.pcbPrimitiveStore.setFills(fills);
        }
        if (virtualSpillNodes) {
            const spills = virtualSpillNodes.shapesMap;
            this.pcbPrimitiveStore.setSpills(spills);
        }
    }

    private async updateSmartViaConfigsCache(pcbLayoutEngine: PcbLayoutEngine | Remote<PcbLayoutEngine>) {
        const smartViaConfigsCache = usePcbEditorUiStore.getState().smartViaConfigsCache ?? {};
        const updatedCache = await pcbLayoutEngine.updateSmartViaConfigsCache(smartViaConfigsCache);
        usePcbEditorUiStore.getState().setSmartViaConfigsCache(updatedCache);
    }

    /**
     * NOTE: This will reuse the nodes inside the existing web worker instead of
     * copying documentState and treeNodes again.
     */
    private async getPcbLayoutEngine(
        documentState: PcbDocumentState,
        treeNodes: PcbNodesMap<AnyPcbBakedNode | AnyPcbNode>,
        pcbLayoutRules: PcbRuleSetsMap,
    ) {
        const primitives = this.pcbPrimitiveStore.getAllNodePrimitives();
        const isDocumentFeatureIslandRemoval = documentState.features?.islandRemoval === "v0";
        const featureFlags = chain(this.featureFlagsStore.getState())
            .pickBy((value) => {
                // State for useFeatureFlags(..) contains an unsubscribe function - we can't send a function over
                // the thread boundary, so functions are filtered
                return typeof value !== "function";
            })
            .mapValues((value, key) => {
                if (key === "enablePcbFillIslandRemoval") {
                    // Island Removal is only available if both the document feature and feature flag are enabled
                    return value && isDocumentFeatureIslandRemoval;
                } else {
                    return value;
                }
            })
            .value() as FeatureFlagValues;
        const autoLayoutData = usePcbEditorUiStore.getState().autoLayoutIterationData;

        if (areWeTestingWithJest() || (featureFlags.disablePcbLayoutEngineWorker ?? false)) {
            const clipperShapeFactory = await new AsyncClipperShapeFactory().load();

            return new PcbLayoutEngine(
                documentState,
                treeNodes,
                primitives,
                pcbLayoutRules,
                clipperShapeFactory,
                FluxLogger,
                kicadFootprintToFluxFootprint,
                featureFlags,
                autoLayoutData,
            );
        }
        // IDEA: rely on PcbLayoutEngineInWorkerMock here instead for jest?
        return await createPcbLayoutEngineWorker(documentState, primitives, featureFlags, autoLayoutData);
    }
}
