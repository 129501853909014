import {
    FeatureFlagClientNotInitializedError,
    isTestAccount,
    IUserData,
    OrganizationHandle,
    SubscriptionManager,
} from "@buildwithflux/core";
import {
    areWeInStorybook,
    areWeTestingWithJest,
    getEnvironmentName,
    Logger,
    logWarningsAndAbove,
    Unsubscriber,
} from "@buildwithflux/shared";
import {initialize, LDClient, LDContext, LDFlagSet} from "launchdarkly-js-client-sdk";
import {LDMultiKindContext, LDSingleKindContext} from "launchdarkly-js-sdk-common";
import {isEqual} from "lodash";

import {CurrentUserService} from "../auth";
import {OrganizationStoreState, UseOrganizationStore} from "../auth/state/organization";
import {FluxLogger} from "../storage_engine/connectors/LogConnector";

// IMPORTANT:
// LD bills based on number of monthly active contexts. Active contexts are defined by the number of unique keys that are
// tracked at *initialize* time or when identifying a context via *identify*. If a key is not specified in either
// scenario, LD creates a unique key for that user/session. Since we initialize the LD client before we have a user
// context, LD recommends to always initialize with the same user key, then replace it via *identify* once we know
// who the user is. Otherwise every session generates a new unique key (which we pay for).
// See LD documentation @ https://docs.launchdarkly.com/sdk/features/user-config#anonymous-users-in-the-nodejs-sdk
// See internal documentation @ [workplace-post/670807980925056](/docs/workplace/670807980925056.md)
const anonymousUser: LDSingleKindContext = {
    kind: "user",
    key: "anonymous-user",
    anonymous: true,
};
const initialUser: LDSingleKindContext = {
    kind: "user",
    key: "initial-user",
    anonymous: true,
};
const testUser: LDSingleKindContext = {
    kind: "user",
    key: "test-user",
    anonymous: false,
};

function isMultiKindContext(context: LDContext | undefined): context is LDMultiKindContext {
    return !!context && (context as LDMultiKindContext)?.kind === "multi";
}

export function isMultiKindContextWithUser(
    context: LDContext | undefined,
): context is LDMultiKindContext & {user: LDSingleKindContext} {
    return isMultiKindContext(context) && !!(context as LDMultiKindContext)?.user;
}

export type FlagSet = Record<string, unknown> | undefined | null; // LDFlagSet is essentially this, but with any

export interface AbstractLaunchDarklyService {
    allFlags(): FlagSet | undefined;
    subscribeToFlags(onChange: (flagValues: FlagSet) => void): Unsubscriber;
    shutdown(): Promise<void>;
}

/**
 * IMPORTANT NOTE: Please don't call this class directly in React components
 *
 * Please see PR description in https://github.com/buildwithflux/flux-app/pull/8833,
 * about how LaunchDarkly works in Flux, how flag values get changed, and the responsibility
 * of this class
 *
 * See https://github.com/buildwithflux/flux-app/pull/8722 for why we use an initialUser
 */
export class LaunchDarklyService implements AbstractLaunchDarklyService {
    protected client?: LDClient;

    private isInitialized = false;
    private isIntializing = false;
    private isShutDown = false;

    private subscriptionManager: SubscriptionManager<"featureFlags", FlagSet>;
    private currentFlagSet: FlagSet;
    private currentContext: LDMultiKindContext | undefined;
    private enterpriseHandles: OrganizationHandle[] = [];
    private readonly siteContext: LDSingleKindContext;

    private organizationStoreUnsubscriber: Unsubscriber | undefined = undefined;
    private currentUserServiceUnsubscriber: Unsubscriber | undefined = undefined;

    constructor(
        private readonly currentUserServiceProvider: () => CurrentUserService,
        private readonly useOrganizationStoreProvider: () => UseOrganizationStore,
        private readonly logger: Logger,
    ) {
        this.subscriptionManager = new SubscriptionManager();

        this.siteContext = {
            kind: "site",
            key: getEnvironmentName() ?? "unknown",
        };

        if (!areWeTestingWithJest() && !areWeInStorybook()) {
            void this.initClient();
        }
    }

    public async shutdown(): Promise<void> {
        this.isShutDown = true;
        this.currentUserServiceUnsubscriber?.();
        this.organizationStoreUnsubscriber?.();
        await this.client?.close();
    }

    public async identifyUser(user: IUserData | null | undefined): Promise<LDFlagSet | undefined> {
        if (!this.client || !this.isInitialized) {
            const error = new FeatureFlagClientNotInitializedError(
                new Error(`identifyUser called on uninitialized service`),
            );
            this.logger.error(error);
            FluxLogger.captureError(error);
            return;
        }

        if (this.isShutDown) {
            const error = new FeatureFlagClientNotInitializedError(
                new Error(`identifyUser called on shut down service`),
            );
            this.logger.error(error);
            FluxLogger.captureError(error);
            return;
        }

        const context = this.contextForUser(user ?? undefined);
        const userContext: LDSingleKindContext | undefined = context?.user as LDSingleKindContext | undefined;
        await this.client.identify(context);

        if (
            userContext?.key === "anonymous-user" ||
            userContext?.key === "initial-user" ||
            userContext?.key === "test-user"
        ) {
            // In case feature flags for test user is the same for initial user, call `updateStateAndNotify`
            // manually when finish identifying
            this.updateStateAndNotify(this.client?.allFlags());
        }
    }

    public allFlags() {
        return this.client?.allFlags();
    }

    public subscribeToFlags(onChange: (flagValues: FlagSet) => void): Unsubscriber {
        const currentValue = this.client?.allFlags();
        if (currentValue != null && Object.keys(currentValue).length > 0) {
            onChange(currentValue);
        }
        return this.subscriptionManager.addSubscription("featureFlags", onChange);
    }

    protected async initClient(): Promise<void> {
        if (this.isInitialized || this.isIntializing || this.isShutDown) {
            return;
        }

        this.isIntializing = true;

        const clientKey = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_KEY_PRODUCTION;

        if (!clientKey) {
            throw new Error("REACT_APP_LAUNCHDARKLY_CLIENT_KEY_PRODUCTION is not set");
        }

        // IMPORTANT: See above for why we reuse the initialUser key at initialize time
        const currentUser = this.currentUserServiceProvider().getCurrentUser();
        const initialContext = this.contextForUser(currentUser);

        this.client = initialize(clientKey, initialContext, {
            streaming: true,
            bootstrap: "localStorage",
            logger: logWarningsAndAbove(console),
        });

        await this.client
            .waitForInitialization()
            .then(() => {
                if (!this.client) {
                    throw new Error("failed to initialize LD client");
                }
                this.isInitialized = true;
                const currentFlagValues = this.client?.allFlags();
                this.updateStateAndNotify(currentFlagValues);

                this.client.on("change", () => {
                    this.updateStateAndNotify(this.client?.allFlags());
                });

                this.subscribeToUserChanges();
                this.subscribeToOrganizationChanges();
            })
            .catch((error) => {
                // we want to know when this fails to initialize but it shouldn't block the user
                FluxLogger.captureError(new FeatureFlagClientNotInitializedError(error));
            });
    }

    private subscribeToUserChanges(): void {
        this.currentUserServiceUnsubscriber = this.currentUserServiceProvider().subscribeToUserChanges((user) => {
            if (user.user && user.suggestedState !== "partiallyAuthenticated") {
                void this.identifyUser(user.user);
            }
        });
    }

    private subscribeToOrganizationChanges(): void {
        this.organizationStoreUnsubscriber = this.useOrganizationStoreProvider().subscribe(
            this.processOrganizationState.bind(this),
        );
        this.processOrganizationState(this.useOrganizationStoreProvider().getState());
    }

    private processOrganizationState(state: OrganizationStoreState): void {
        const enterpriseHandles = state.memberships
            .filter((membership) => membership.organization.visibility === "unlisted")
            .map((membership) => membership.organization.handle)
            .sort();

        if (!isEqual(enterpriseHandles, this.enterpriseHandles)) {
            this.enterpriseHandles = enterpriseHandles;
            void this.identifyUser(this.currentUserServiceProvider().getCurrentUser());
        }
    }

    private updateStateAndNotify(newFlags: FlagSet) {
        const newContext = this.client?.getContext();
        const contextHasIdentifiedUser =
            !!newContext && isMultiKindContextWithUser(newContext) && newContext.user.key !== initialUser.key;
        const flagsChanged = !isEqual(this.currentFlagSet, newFlags);
        const contextChanged = !isEqual(this.currentContext, newContext);

        // See more detail in https://github.com/buildwithflux/flux-app/pull/8722 about why
        // we setup these conditions here.
        // These conditions are tested by `LaunchDarklyService.test.ts`
        if (contextHasIdentifiedUser && (flagsChanged || contextChanged)) {
            this.currentFlagSet = newFlags;
            this.currentContext = newContext;
            this.subscriptionManager.notify("featureFlags", newFlags);
        }
    }

    private contextForUser(currentUser: IUserData | undefined): LDMultiKindContext {
        if (!currentUser) {
            return {
                kind: "multi",
                user: initialUser,
                site: this.siteContext,
            };
        }

        if (currentUser.isAnonymous) {
            return {
                kind: "multi",
                user: anonymousUser,
                site: this.siteContext,
            };
        }

        if (isTestAccount(currentUser, "strict")) {
            return {
                kind: "multi",
                user: testUser,
                site: this.siteContext,
            };
        }

        const user: LDSingleKindContext = {
            kind: "user",
            key: currentUser.handle,
            anonymous: false,
            email: currentUser.email,
            name: currentUser.full_name,
            created_at: currentUser.created_at,
        };

        // Length check so we don't create too many duplicate contexts, because this logic is not present in the backend yet
        if (this.enterpriseHandles.length > 0) {
            user.enterprise_organization_handles = this.enterpriseHandles;
        }

        return {
            kind: "multi",
            user,
            site: this.siteContext,
        };
    }
}

export class TestLaunchDarklyService extends LaunchDarklyService {
    public override async initClient(): Promise<void> {
        await super.initClient();
    }

    public getContext() {
        return this.client?.getContext();
    }
}

export class MockLaunchDarklyService implements AbstractLaunchDarklyService {
    constructor(private readonly flags: FlagSet = {}) {}

    allFlags(): FlagSet | undefined {
        return this.flags;
    }

    subscribeToFlags(_onChange: (flagValues: FlagSet) => void): Unsubscriber {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    }

    shutdown(): Promise<void> {
        return Promise.resolve();
    }
}
