import {isStripeVerificationValueCorrect} from "@buildwithflux/core";
import {FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {
    AmountInDollars,
    getCurrentOnboardingStep,
    getCurrentOnboardingStepForUser,
    IUserData,
    IUserPrivateMetadata,
    OnboardingFlow,
    OnboardingStep,
    PaymentPlanCategory,
    SuccessfulStripeVerificationUrlParam,
    SubscriptionStatus,
    UserUid,
    ExperimentConfiguration,
    PlanEpoch,
    isExperimentActive,
    ExperimentConfigurationKind,
} from "@buildwithflux/models";
import {UserPrivateMetadataRepository, UserRepository} from "@buildwithflux/repositories";
import {areWeTestingWithJest, isDevEnv, Logger, wait} from "@buildwithflux/shared";
import type {FirebaseError} from "@firebase/util";
import {Draft} from "immer";
import {noop} from "lodash";
import {StoreApi} from "zustand";
import {devtools} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {createStore} from "zustand/vanilla";

import {ChosenUserPlan} from "../../components/common/components/dialog/ChoosePlanDialogContent";
import {ChosenUserPlanDeprecated} from "../../components/common/components/dialog/ChoosePlanDialogContentDeprecated";
import {createBoundUseStoreHook} from "../../helpers/zustand";
import {useFluxServices} from "../../injection/hooks";
import {CurrentUserService} from "../../modules/auth";
import {FeatureFlagsState, FeatureFlagsStore} from "../feature_flags/FeatureFlagsStore";
import {experimentVariantFromConfiguration} from "../feature_flags/variants";
import {AnalyticsStorage} from "../storage_engine/AnalyticsStorage";
import {SurfaceBasedTrackingEvents} from "../storage_engine/common/SurfaceBasedTrackingEvents";
import {FluxLogger} from "../storage_engine/connectors/LogConnector";

import {
    assertOnboardingDialogStateInitialized,
    getSearchParamsFromLocation,
    onboardingDialogStateIsAtStep,
    removeAllOnboardingSearchParamsFromLocation,
} from "./helpers";
import {SurveyData, getCurrentSurveyData, surveyDataIsComplete} from "./survey";
import {
    ChooseInvitesState,
    ChoosePlanState,
    ChooseInvitesStepCallbackArgs,
    OnboardingStoreState,
    SurveyState,
    UninitializedState,
    UseOnboardingDialogStore,
} from "./types";

/**
 * Service dependencies for creating the store
 */
type Services = {
    currentUserService: CurrentUserService;
    userRepository: UserRepository;
    userPrivateMetadataRepository: UserPrivateMetadataRepository;
    analyticsStorage: AnalyticsStorage;
    functionsAdapter: FunctionsAdapter;
    featureFlagsStore: FeatureFlagsStore;
    logger: Logger;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const onUserChanged = async (_user: IUserData, _privateMetadata: IUserPrivateMetadata) => {}; // noop for most steps

/**
 * The initial uninitialized state of the store
 */
const uninitialized: UninitializedState = {
    step: "uninitialized",
    userUid: undefined,
    userHandle: undefined,
    flow: undefined,
    completedSteps: [],
    isShown: false,
    allowsDismissal: false,
    onUserChanged: undefined,
    onDismiss: undefined,
    callbacks: {},
};

// Step functions themselves don't change these in-band
type NonStepStateKeys = "userUid" | "userHandle" | "completedSteps" | "flow";

export type StateProducer = (draftState: Draft<OnboardingStoreState>) => void;
export type Set = (
    nextStateProducer: StateProducer | OnboardingStoreState,
    shouldReplace: boolean,
    actionNameForDebugging: string,
) => void;
export type Get = () => OnboardingStoreState;
export type StepFunction = (set: Set, get: Get, services: Services) => Promise<void>;

function getStateUpdater(
    state: Omit<OnboardingStoreState, NonStepStateKeys> & Partial<Pick<OnboardingStoreState, NonStepStateKeys>>,
): StateProducer {
    return (draftState) => {
        draftState.step = state.step;
        draftState.isShown = state.isShown;
        draftState.allowsDismissal = state.allowsDismissal;
        draftState.onUserChanged = state.onUserChanged;
        draftState.onDismiss = state.onDismiss;
        draftState.callbacks = state.callbacks;

        for (const step of state.completedSteps ?? []) {
            if (!draftState.completedSteps.includes(step)) {
                draftState.completedSteps.push(step);
            }
        }
    };
}

async function markStepCompleted(
    set: Set,
    get: Get,
    services: Services,
    step: OnboardingStep,
    reason: string,
): Promise<void> {
    const state = get();
    assertOnboardingDialogStateInitialized(state);

    set(
        (draft) => {
            if (!draft.completedSteps.includes(step)) {
                draft.completedSteps.push(step);
            }
        },
        false,
        `markStepCompleted(${step}, reason: ${reason})`,
    );

    await services.userPrivateMetadataRepository.markOnboardingStepsComplete(state.userUid, [step]);
}

function getNextStep(get: Get, fromCurrentStep: OnboardingStep): OnboardingStep {
    const state = get();
    const {flow, completedSteps} = state;

    const stepsWithCurrentCompleted = new Set<OnboardingStep>(completedSteps);
    stepsWithCurrentCompleted.add(fromCurrentStep);

    return getCurrentOnboardingStep(flow ?? OnboardingFlow.enum.standard, stepsWithCurrentCompleted);
}

/**
 * Moves to the next step
 */
async function moveToNextStep(
    set: Set,
    get: Get,
    services: Services,
    fromStep: OnboardingStep,
    reason: string,
): Promise<void> {
    const toStep = getNextStep(get, fromStep);

    const state = get();
    assertOnboardingDialogStateInitialized(state);

    // In parallel, start loading the new state, and mark the current step complete
    await Promise.all([
        await markStepCompleted(set, get, services, fromStep, reason),
        await getStepFunction(toStep)(set, get, services),
    ]);
}

/**
 * Gets the step function for a given step
 */
function getStepFunction(step: OnboardingStep): StepFunction {
    switch (step) {
        case OnboardingStep.enum.survey:
            return surveyStep;
        case OnboardingStep.enum.chooseInvites:
            return chooseInvitesStep;
        case OnboardingStep.enum.choosePlan:
            return choosePlanStep;
        case OnboardingStep.enum.getStarted:
            return getStartedStep;
        case OnboardingStep.enum.done:
            return doneStep;
        default:
            throw new Error(`Unknown step: ${step}`);
    }
}

/**
 * As we start up the store, we subscribe to the current user service to receive changes in the current
 * user and user private metadata. We mainly do this so that we can reset the state if the user logs out
 * and start the initial state if the user logs in.
 *
 * We don't closely track changes to the user nor their privateMetadata: instead, steps that need that
 * information can retrieve it from services.currentUserService.getCurrentUser() - doing it this way
 * prevents duplicate copies of the user data being stored on the store, which could otherwise go out
 * of sync.
 *
 * The one exception is that we notify the current step function when the user changes, so that it can
 * react to the change if necessary. This should _not_ react to onboarding data itself (which is stored
 * internally on this store as the source-of-truth)
 */
async function updateUser(
    set: Set,
    get: Get,
    services: Services,
    currentUserInfo: {
        user: IUserData | undefined;
        privateMetadata: IUserPrivateMetadata | undefined;
    },
): Promise<void> {
    let state = get() ?? uninitialized;
    const user = currentUserInfo.user;

    // If a new user has logged in, or the current user has logged out, the state needs to go back to uninitialized
    if (user?.uid !== state?.userUid || user?.handle !== state?.userHandle) {
        state = uninitialized;
        set(state, false, "userChanged");
    }

    // And if there's no current user, that's all we need to do
    if (!user || user.isAnonymous) {
        set(state, false, "noUser");
        return;
    }

    // Fake out the privateMetadata if it's completely missing (it's probably still loading)
    const privateMetadata = currentUserInfo.privateMetadata ?? {uid: user.uid};

    if (state.step === "uninitialized") {
        // We do not proceed any further if we are already marked onboarded
        if (privateMetadata?.onboarding?.onboarded === true) {
            return;
        }

        // Now, if we are currently uninitialized, we need to find and set up the initial state of the dialog
        const flow = getOnboardingFlowForUser({privateMetadata, featureFlags: get().featureFlags});
        services.logger.debug(`OnboardingStore: got initial onboarding flow for user: ${flow}`, {
            flow,
            featureFlags: state.featureFlags,
        });

        set(
            (draft) => {
                draft.userUid = user.uid;
                draft.userHandle = user.handle;
                draft.completedSteps = privateMetadata.onboarding?.completedSteps ?? [];
                draft.flow = flow;
            },
            false,
            "initializing",
        );

        // We should persist the flow we're going to use, so we can always use the same one later
        if (!privateMetadata.onboarding?.flow) {
            void services.userPrivateMetadataRepository.update(user.uid, {
                onboarding: {
                    flow,
                },
            });
        }

        const nextStep = getCurrentOnboardingStepForUser(flow, privateMetadata);

        if (nextStep === OnboardingStep.enum.survey) {
            await surveyStep(set, get, services);
        } else if (nextStep === OnboardingStep.enum.chooseInvites) {
            await chooseInvitesStep(set, get, services);
        } else if (nextStep === OnboardingStep.enum.choosePlan) {
            await choosePlanStep(set, get, services);
        } else if (nextStep === OnboardingStep.enum.getStarted) {
            await getStartedStep(set, get, services);
        } else if (nextStep === OnboardingStep.enum.done) {
            await doneStep(set, get, services);
        } else {
            throw new Error("Unknown step: " + nextStep);
        }
    } else {
        // And if we are already initialized, we need to let the current step function know about the possible update
        state.onUserChanged ? await state.onUserChanged(user, privateMetadata) : void 0;
    }
}

function updateFeatureFlags(set: Set, get: Get, service: Services, state: FeatureFlagsState): void {
    set(
        (draft) => {
            if (!draft.featureFlags) {
                draft.featureFlags = {};
            }

            draft.featureFlags.segmentOnboardingFlowStreamlinedExperiment =
                state.segmentOnboardingFlowStreamlinedExperiment;
        },
        false,
        "updateFeatureFlags",
    );
}

export function getOnboardingFlowForUser({
    privateMetadata,
    featureFlags,
}: {
    privateMetadata: IUserPrivateMetadata;
    featureFlags: {segmentOnboardingFlowStreamlinedExperiment?: ExperimentConfiguration | undefined} | undefined;
}): OnboardingFlow {
    if (privateMetadata.onboarding?.flow) {
        return privateMetadata.onboarding.flow;
    }

    const planEpoch = privateMetadata.creationFlags?.planEpoch ?? PlanEpoch.enum.legacy;

    if (planEpoch === PlanEpoch.enum.legacy) {
        // We can't support the streamlined flow for the legacy plan epoch, because we need educational users to be
        // presented with the choosePlan step, otherwise they won't be able to mark themselves educational
        return OnboardingFlow.enum.standard;
    }

    if (!featureFlags) {
        // This is usually because no feature flags have loaded by the time we need to decide on the flow to use
        // This is minimized by us using a configuration, and deciding the final value at the last second
        return OnboardingFlow.enum.standard;
    }

    const {segmentOnboardingFlowStreamlinedExperiment} = featureFlags;

    return isExperimentActive(
        experimentVariantFromConfiguration(
            privateMetadata.uid,
            segmentOnboardingFlowStreamlinedExperiment ?? {kind: ExperimentConfigurationKind.enum.off},
        ),
    )
        ? OnboardingFlow.enum.streamlined
        : OnboardingFlow.enum.standard;
}

async function markUserEducational(functionsAdapter: FunctionsAdapter, attempt = 1): Promise<void> {
    try {
        await functionsAdapter.userSetEducational();
    } catch (error: unknown) {
        if ((error as FirebaseError)?.code === "functions/internal" && attempt < 3) {
            /*
             * This is some sort of CORS error, perhaps due to a lack of Access-Control-Allow-Credentials
             * in the CORS response
             *
             * But as this is an onCall, Firebase is supposed to be handling the CORS response for us,
             * and we don't really have control over it.
             *
             * All we can do is retry, which actually usually succeeds after some delay. This same handling
             * has been applied elsewhere in the codebase, e.g. frontend/src/modules/payments/state/credit.ts:86
             *
             * TODO: FLUX-4073 upgrading to firebase-functions v2 in backend may help, or upgrading Firebase in general
             */
            await wait(2_000);
            return await markUserEducational(functionsAdapter, attempt + 1);
        }

        throw error;
    }
}

/**
 * If there is an ?sv={hash} search param, indicating a successful Stripe checkout, we continue to
 * the next step.
 *
 * This is the main means by which we move to the next step for paid plans and trials
 *
 * A boolean is returned so callers can optionally skip their own changes to the state
 */
async function checkForSuccessfulPayment(set: Set, get: Get, services: Services, userUid: UserUid): Promise<boolean> {
    const searchParams = getSearchParamsFromLocation();

    if (
        searchParams.has(SuccessfulStripeVerificationUrlParam) &&
        isStripeVerificationValueCorrect(userUid, searchParams.get(SuccessfulStripeVerificationUrlParam) ?? "")
    ) {
        void services.analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
            step: "stripeResult",
            action: "1", // Backward-compatible, but weird (we'd set this to "1" because it came from the stripeSuccess URL param we set on the successUrl)
            content_type: "onboardingDialog",
        });

        await moveToNextStep(set, get, services, OnboardingStep.enum.choosePlan, "surveyCompleteElsewhere");
        return true;
    }

    return false;
}

/**
 * A secondary means by which we move to the next step: if the user has completed a checkout in a
 * different tab or window, and we have processed the Stripe webhook in the backend
 *
 * A boolean is returned so callers can skip their own changes to the state
 */
async function checkForActiveSubscription(
    set: Set,
    get: Get,
    services: Services,
    privateMetadata: IUserPrivateMetadata,
): Promise<boolean> {
    if (
        Object.values(privateMetadata?.payment?.subscriptions || {}).filter(
            (subscription) =>
                subscription.status === SubscriptionStatus.enum.active ||
                subscription.status === SubscriptionStatus.enum.trialing ||
                subscription.status === SubscriptionStatus.enum.incomplete,
        ).length > 0
    ) {
        await moveToNextStep(
            set,
            get,
            services,
            OnboardingStep.enum.choosePlan,
            "onUserChanged(nowHasActiveSubscription)",
        );
        return true;
    }

    return false;
}

// Step functions

async function surveyStep(set: Set, get: Get, services: Services): Promise<void> {
    services.logger.debug("Starting surveyStep");

    const state = get();
    assertOnboardingDialogStateInitialized(state);
    const {userHandle, userUid} = state;

    // If survey is already complete when starting the survey step, move directly to the next step
    if (
        surveyDataIsComplete(
            getCurrentSurveyData({
                user: services.currentUserService.getCurrentUser(),
                privateMetadata: services.currentUserService.getCurrentUserPrivateMetadata(),
            }),
        )
    ) {
        await moveToNextStep(set, get, services, OnboardingStep.enum.survey, "surveyAlreadyComplete");
        return;
    }

    const onUpdateSurvey = async (surveyData: SurveyData) => {
        await Promise.all([
            services.userRepository.update(userHandle, {
                full_name: surveyData.fullName,
            }),
            services.userPrivateMetadataRepository.update(userUid, {
                uid: userUid,
                personal: {
                    role: surveyData.role || null,
                    company: surveyData.company || null,
                    tools: surveyData.tools || null,
                    hearAboutFlux: surveyData.hearAboutFlux || null,
                },
            }),
        ]);
    };

    set(
        getStateUpdater({
            step: OnboardingStep.enum.survey,
            isShown: true,
            allowsDismissal: false,
            callbacks: {
                onUpdateSurvey,

                async onCompleteSurvey(surveyData: SurveyData) {
                    await onUpdateSurvey(surveyData);
                    await moveToNextStep(set, get, services, OnboardingStep.enum.survey, "onCompleteSurvey");
                },
            },
            onUserChanged,
        }),
        false,
        "surveyStep",
    );
}

async function chooseInvitesStep(set: Set, get: Get, services: Services): Promise<void> {
    services.logger.debug("Starting chooseInvitesStep");

    const state = get();
    assertOnboardingDialogStateInitialized(state);
    const {userUid} = state;

    async function onCompleteInvites(invitesData: ChooseInvitesStepCallbackArgs): Promise<void> {
        const {invitedEmails} = invitesData;

        if (invitedEmails.length > 0) {
            await services.userPrivateMetadataRepository.update(userUid, {
                onboarding: {
                    invitedEmails,
                },
            });
        }

        await moveToNextStep(set, get, services, OnboardingStep.enum.chooseInvites, "onCompleteInvites");
        return;
    }

    set(
        getStateUpdater({
            step: OnboardingStep.enum.chooseInvites,
            isShown: true,
            allowsDismissal: false,
            callbacks: {
                onCompleteInvites,
            },
            onUserChanged,
        }),
        false,
        "chooseInvitesStep",
    );
}

async function choosePlanStep(set: Set, get: Get, services: Services): Promise<void> {
    services.logger.debug("Starting choosePlanStep");

    const state = get();
    assertOnboardingDialogStateInitialized(state);

    const completedFromSuccessfulPayment = await checkForSuccessfulPayment(set, get, services, state.userUid);

    if (completedFromSuccessfulPayment) {
        return;
    }

    const privateMetadata = services.currentUserService.getCurrentUserPrivateMetadata();

    if (privateMetadata) {
        const completedFromActiveSubscription = await checkForActiveSubscription(set, get, services, privateMetadata);

        if (completedFromActiveSubscription) {
            return;
        }
    }

    const onPlanChosen = async (planChosen: ChosenUserPlan | ChosenUserPlanDeprecated, price?: AmountInDollars) => {
        void services.analyticsStorage.trackChoosePlan(planChosen, price);

        if (planChosen === PaymentPlanCategory.enum.userLegacyEdu) {
            try {
                await markUserEducational(services.functionsAdapter);
                await moveToNextStep(set, get, services, OnboardingStep.enum.choosePlan, "onPlanChosen(edu)");
            } catch (err) {
                services.logger.error("Failed to set user as educational", err);
                FluxLogger.captureError(err);
            }
        } else if (
            planChosen === PaymentPlanCategory.enum.userLegacyFree ||
            planChosen === PaymentPlanCategory.enum.userNoPlan
        ) {
            /*
             * Don't call this for other plans, because the user hasn't completed the plan step
             * until they've paid in Stripe for those. At which point, we'll re-enter this step,
             * and the above logic will re-run, detect Stripe success and continue on to the next
             * step.
             */
            await moveToNextStep(set, get, services, OnboardingStep.enum.choosePlan, "onPlanChosen(freeOrNoPlan)");
        }
    };

    set(
        getStateUpdater({
            step: OnboardingStep.enum.choosePlan,
            isShown: true,
            allowsDismissal: false,
            callbacks: {onPlanChosen},

            async onUserChanged(_user: IUserData, privateMetadata: IUserPrivateMetadata) {
                await checkForActiveSubscription(set, get, services, privateMetadata);
            },
        }),
        false,
        "choosePlanStep",
    );
}

async function getStartedStep(set: Set, get: Get, services: Services): Promise<void> {
    services.logger.debug("Starting getStartedStep");

    set(
        getStateUpdater({
            step: OnboardingStep.enum.getStarted,
            isShown: true,
            allowsDismissal: true,
            async onDismiss() {
                removeAllOnboardingSearchParamsFromLocation();
                await moveToNextStep(set, get, services, OnboardingStep.enum.getStarted, "onDismissDuringGetStarted");
            },
            callbacks: {},
            onUserChanged,
        }),
        false,
        "getStartedStep",
    );
}

async function doneStep(set: Set, get: Get, services: Services): Promise<void> {
    services.logger.debug("Starting doneStep");

    const state = get();
    assertOnboardingDialogStateInitialized(state);

    const {userUid} = state;

    await Promise.all([
        services.userPrivateMetadataRepository.update(userUid, {onboarding: {onboarded: true}}),
        markStepCompleted(set, get, services, OnboardingStep.enum.done, "doneStep"),
    ]);

    removeAllOnboardingSearchParamsFromLocation();

    set(
        getStateUpdater({
            step: OnboardingStep.enum.done,
            isShown: false,
            allowsDismissal: false,
            onUserChanged: undefined,
            callbacks: {},
        }),
        false,
        "doneStep",
    );
}

// End of Step functions

/**
 * The onboarding store holds the state for the OnboardingDialog, including which "step" the user is on, and how to
 * move between steps
 */
export function createOnboardingDialogStore(services: Services): StoreApi<OnboardingStoreState> {
    return createStore<OnboardingStoreState>()(
        devtools(
            immer((set, get): OnboardingStoreState => {
                // Set up the user and feature subscriber
                setTimeout(() => {
                    const featureFlagUnsubscriber = services.featureFlagsStore.subscribe(
                        (state) => void updateFeatureFlags(set, get, services, state),
                    );

                    // And call once with the current value, in case the subscription doesn't emit a value
                    void updateFeatureFlags(set, get, services, services.featureFlagsStore.getState());

                    const userChangeUnsubscriber = services.currentUserService.subscribeToUserChanges(
                        (currentUserInfo) => {
                            void updateUser(set, get, services, currentUserInfo).catch((err) => {
                                services.logger.error("Error while responding to user change", err);
                                FluxLogger.captureError(err);
                            });
                        },
                    );

                    // And call once with the current value, in case the subscription doesn't emit a value
                    void updateUser(set, get, services, {
                        user: services.currentUserService.getCurrentUser(),
                        privateMetadata: services.currentUserService.getCurrentUserPrivateMetadata(),
                    });

                    // Store the subscriber for later cleanup
                    set(
                        (draft) => {
                            draft.shutdown = () => {
                                userChangeUnsubscriber();
                                featureFlagUnsubscriber();
                            };
                        },
                        false,
                        "createOnboardingDialogStore(addShutdown)",
                    );
                }, 0);

                return uninitialized;
            }),
            {enabled: isDevEnv() && !areWeTestingWithJest(), name: "OnboardingDialogStore"},
        ),
    );
}

export function createOnboardingDialogStoreHook(services: Services): UseOnboardingDialogStore {
    return createBoundUseStoreHook(createOnboardingDialogStore(services));
}

export function useOnboardingDialogCurrentStep(): OnboardingStep | "uninitialized" {
    return useFluxServices().useOnboardingDialogStore((state) => state.step);
}

export function useOnboardingDialogCurrentFlow(): OnboardingFlow {
    return useFluxServices().useOnboardingDialogStore((state) =>
        "flow" in state && state.flow ? state.flow : OnboardingFlow.enum.standard,
    );
}

export function useOnboardingDialogIsShown(): boolean {
    return useFluxServices().useOnboardingDialogStore((state) => state.isShown);
}

export function useOnboardingDialogOnDismiss(): () => void {
    return useFluxServices().useOnboardingDialogStore((state) =>
        state.allowsDismissal ? state.onDismiss ?? noop : noop,
    );
}

export function useOnboardingDialogOnCompleteSurvey(): SurveyState["callbacks"]["onCompleteSurvey"] {
    return useFluxServices().useOnboardingDialogStore((state) => {
        if (!onboardingDialogStateIsAtStep(state, "survey")) {
            throw new Error("Not at the survey step");
        }

        return state.callbacks.onCompleteSurvey;
    });
}

export function useOnboardingDialogOnUpdateSurvey(): SurveyState["callbacks"]["onUpdateSurvey"] {
    return useFluxServices().useOnboardingDialogStore((state) => {
        if (!onboardingDialogStateIsAtStep(state, "survey")) {
            throw new Error("Not at the survey step");
        }

        return state.callbacks.onUpdateSurvey;
    });
}

export function useOnboardingDialogOnCompleteInvites(): ChooseInvitesState["callbacks"]["onCompleteInvites"] {
    return useFluxServices().useOnboardingDialogStore((state) => {
        if (!onboardingDialogStateIsAtStep(state, OnboardingStep.enum.chooseInvites)) {
            throw new Error("Not at the chooseInvites step");
        }

        return state.callbacks.onCompleteInvites;
    });
}

export function useOnboardingDialogOnPlanChosen(): ChoosePlanState["callbacks"]["onPlanChosen"] {
    return useFluxServices().useOnboardingDialogStore((state) => {
        if (!onboardingDialogStateIsAtStep(state, OnboardingStep.enum.choosePlan)) {
            throw new Error("Not at the choosePlan step");
        }

        return state.callbacks.onPlanChosen;
    });
}
