import {hasBrowserWindowAccess, isDevEnv} from "@buildwithflux/shared";
import {AnyAction, configureStore, StoreEnhancer, ThunkAction} from "@reduxjs/toolkit";
import {createReduxEnhancer} from "@sentry/react";
import {set} from "lodash";

import {FluxServices} from "./injection/container";
import {
    getActiveServicesContainerSafelyFromRoot,
    getActiveServicesContainerBadlyAsServiceLocator,
} from "./injection/singleton";
import {solderEnhancer} from "./redux/enhancers/solderEnhancer";
import {batchDispatchAndPatchMiddleware, currentUserMiddleware} from "./redux/middlewares";
import {epicMiddleware, rootEpic} from "./redux/middlewares/epic";
import {rootReducer} from "./redux/reducers/rootReducer";
import type {IApplicationState} from "./state";

// Fixes TS2742
import "redux-thunk";

const sentryReduxEnhancer = createReduxEnhancer({
    // attaches a redux_state.json file to every Sentry event by default, disable it until needed
    attachReduxState: false,
    // if redux state is needed, it gets very large (5MB) and is included with every event which quickly eats our 1GB capacity
    // so, at least filter out unnecessary data before sending
    // e.g., state.changeHistory.changeHistoryEntries = [] or at least just a small subset of it
    stateTransformer: (state) => {
        return state;
    },
});

const enhancers: StoreEnhancer<Record<string, never>, Record<string, never>>[] = [sentryReduxEnhancer, solderEnhancer];

// eslint-disable-next-line import/no-unused-modules -- this is not a false positive, we only use the default export so far
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        // NOTE: we removed the default redux toolkit middleware because it was
        // too slow for large documents, even in development, adding 10ms+ to
        // dispatch and accounting for more time spent than app code. Re-enable
        // as needed. See https://redux-toolkit.js.org/api/getDefaultMiddleware
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: {extraArgument: getActiveServicesContainerSafelyFromRoot()},
        }).concat([
            batchDispatchAndPatchMiddleware,
            epicMiddleware,
            currentUserMiddleware(getActiveServicesContainerBadlyAsServiceLocator),
        ]),
    enhancers,
});

epicMiddleware.run(rootEpic);

export type IApplicationStore = typeof store;

export type AppThunkAction<Returns, Emits extends AnyAction = AnyAction> = ThunkAction<
    Returns,
    IApplicationState,
    FluxServices,
    Emits
>;

// Alternatively, window.__FLUX__.services.reduxStoreService.getStore() is available in all environments
if (isDevEnv() && hasBrowserWindowAccess()) {
    set(window, ["__FLUX__", "store"], store);
}

export default store;
