import {create, StateCreator, StoreApi, UseBoundStore} from "zustand";

export const KeyMap: {[key: string]: {name: string; code: number; symbol?: string}} = {
    meta: {name: "Meta", code: 91, symbol: "⌘"},
    cmd: {name: "Cmd", code: 91, symbol: "⌘"},
    period: {name: ".", code: 190, symbol: "."},
    shift: {name: "Shift", code: 16, symbol: "⇧"},
    ctrl: {name: "Ctrl", code: 17, symbol: "^"},
    alt: {name: "Alt", code: 18, symbol: "⌥"},
    delete: {name: "Delete", code: 46, symbol: "⌦"},
    backspace: {name: "Backspace", code: 8, symbol: "⌫"},
    // escape: {name: "Escape", code: 27, symbol: "⎋"},
    escape: {name: "Escape", code: 27, symbol: "Esc"},
    enter: {name: "Enter", code: 13, symbol: "⏎"},
    left: {name: "Left", code: 37, symbol: "←"},
    arrowleft: {name: "ArrowLeft", code: 37, symbol: "←"},
    up: {name: "Up", code: 38, symbol: "↑"},
    arrowup: {name: "ArrowUp", code: 38, symbol: "↑"},
    right: {name: "Right", code: 39, symbol: "→"},
    arrowright: {name: "ArrowRight", code: 39, symbol: "→"},
    down: {name: "Down", code: 40, symbol: "↓"},
    arrowdown: {name: "ArrowDown", code: 40, symbol: "↓"},
    space: {name: " ", code: 32, symbol: " "},
    tab: {name: "Tab", code: 9, symbol: "⇥"},
    capslock: {name: "CapsLock", code: 20, symbol: "⇪"},
    fn: {name: "Fn", code: 0},
    printscreen: {name: "PrintScreen", code: 44},
    scrolllock: {name: "ScrollLock", code: 145},
    pause: {name: "Pause", code: 19},
    insert: {name: "Insert", code: 45},
    home: {name: "Home", code: 36},
    pageup: {name: "PageUp", code: 33},
    pagedown: {name: "PageDown", code: 34},
    end: {name: "End", code: 35},
    numlock: {name: "NumLock", code: 144},
    divide: {name: "/", code: 111, symbol: "÷"},
    multiply: {name: "*", code: 106, symbol: "×"},
    subtract: {name: "-", code: 109, symbol: "−"},
    add: {name: "+", code: 107, symbol: "+"},
    decimal: {name: ".", code: 110, symbol: "."},
    equal: {name: "=", code: 187, symbol: "="},
    minus: {name: "-", code: 189, symbol: "-"},
    plus: {name: "+", code: 187, symbol: "+"},
    bracketleft: {name: "[", code: 219, symbol: "["},
    bracketright: {name: "]", code: 221, symbol: "]"},
    backslash: {name: "\\", code: 220, symbol: "\\"},
    semicolon: {name: ";", code: 186, symbol: ";"},
    quote: {name: "'", code: 222, symbol: "'"},
    comma: {name: ",", code: 188, symbol: ","},
    slash: {name: "/", code: 191, symbol: "/"},
    backquote: {name: "`", code: 192, symbol: "`"},
    "0": {name: "0", code: 48, symbol: "0"},
    "1": {name: "1", code: 49, symbol: "1"},
    "2": {name: "2", code: 50, symbol: "2"},
    "3": {name: "3", code: 51, symbol: "3"},
    "4": {name: "4", code: 52, symbol: "4"},
    "5": {name: "5", code: 53, symbol: "5"},
    "6": {name: "6", code: 54, symbol: "6"},
    "7": {name: "7", code: 55, symbol: "7"},
    "8": {name: "8", code: 56, symbol: "8"},
    "9": {name: "9", code: 57, symbol: "9"},
    A: {name: "A", code: 65, symbol: "A"},
    B: {name: "B", code: 66, symbol: "B"},
    C: {name: "C", code: 67, symbol: "C"},
    D: {name: "D", code: 68, symbol: "D"},
    E: {name: "E", code: 69, symbol: "E"},
    F: {name: "F", code: 70, symbol: "F"},
    G: {name: "G", code: 71, symbol: "G"},
    H: {name: "H", code: 72, symbol: "H"},
    I: {name: "I", code: 73, symbol: "I"},
    J: {name: "J", code: 74, symbol: "J"},
    K: {name: "K", code: 75, symbol: "K"},
    L: {name: "L", code: 76, symbol: "L"},
    M: {name: "M", code: 77, symbol: "M"},
    N: {name: "N", code: 78, symbol: "N"},
    O: {name: "O", code: 79, symbol: "O"},
    P: {name: "P", code: 80, symbol: "P"},
    Q: {name: "Q", code: 81, symbol: "Q"},
    R: {name: "R", code: 82, symbol: "R"},
    S: {name: "S", code: 83, symbol: "S"},
    T: {name: "T", code: 84, symbol: "T"},
    U: {name: "U", code: 85, symbol: "U"},
    V: {name: "V", code: 86, symbol: "V"},
    W: {name: "W", code: 87, symbol: "W"},
    X: {name: "X", code: 88, symbol: "X"},
    Y: {name: "Y", code: 89, symbol: "Y"},
    Z: {name: "Z", code: 90, symbol: "Z"},
    f1: {name: "F1", code: 112, symbol: "F1"},
    f2: {name: "F2", code: 113, symbol: "F2"},
    f3: {name: "F3", code: 114, symbol: "F3"},
    f4: {name: "F4", code: 115, symbol: "F4"},
    f5: {name: "F5", code: 116, symbol: "F5"},
    f6: {name: "F6", code: 117, symbol: "F6"},
    f7: {name: "F7", code: 118, symbol: "F7"},
    f8: {name: "F8", code: 119, symbol: "F8"},
    f9: {name: "F9", code: 120, symbol: "F9"},
    f10: {name: "F10", code: 121, symbol: "F10"},
    f11: {name: "F11", code: 122, symbol: "F11"},
    f12: {name: "F12", code: 123, symbol: "F12"},
    f13: {name: "F13", code: 124, symbol: "F13"},
    f14: {name: "F14", code: 125, symbol: "F14"},
    f15: {name: "F15", code: 126, symbol: "F15"},
    f16: {name: "F16", code: 127, symbol: "F16"},
    f17: {name: "F17", code: 128, symbol: "F17"},
    f18: {name: "F18", code: 129, symbol: "F18"},
    f19: {name: "F19", code: 130, symbol: "F19"},
    f20: {name: "F20", code: 131, symbol: "F20"},
    f21: {name: "F21", code: 132, symbol: "F21"},
    f22: {name: "F22", code: 133, symbol: "F22"},
    f23: {name: "F23", code: 134, symbol: "F23"},
    f24: {name: "F24", code: 135, symbol: "F24"},
    contextmenu: {name: "ContextMenu", code: 93, symbol: "☰"},
};

export type KeyType = keyof typeof KeyMap;

export type KeyboardShortcutType = {
    keys: KeyType | KeyType[];
    name: string;
    description?: string;
};

export function keysToArray(keys: KeyType | KeyType[] | undefined): KeyType[] {
    if (!keys) return [];
    return Array.isArray(keys) ? keys : [keys];
}

export interface KeyboardShortcutState {
    activeShortcuts: KeyboardShortcutType[];
    showShortcutBar(key: KeyboardShortcutBarType): void;
    hideShortcutBar(): void;
}

const shortcutsType: Record<KeyboardShortcutBarType, KeyboardShortcutType[]> = {
    RoutingAWire: [
        {keys: "F", name: "Flip Elbow"},
        {keys: "escape", name: "Cancel"},
    ],
    RoutingATrace: [
        {keys: "F", name: "Flip Elbow"},
        {keys: "V", name: "Switch Layer"},
        {keys: "W", name: "Cycle Preferred Trace Width"},
        {keys: "shift", name: "Unconstrain"},
        {keys: "cmd", name: "Toggle Pair Routing"},
        {keys: "escape", name: "Cancel"},
    ],
    RoutingAPolygon: [
        {keys: "shift", name: "Constrain"},
        {keys: "cmd", name: "Cancel"},
    ],
    DraggingATrace: [
        {keys: "shift", name: "Unconstrain"},
        {keys: "cmd", name: "Disconnect"},
    ],
};

export type KeyboardShortcutBarType = "RoutingAWire" | "RoutingATrace" | "RoutingAPolygon" | "DraggingATrace";
export type UseKeyboardShortcutStore = UseBoundStore<StoreApi<KeyboardShortcutState>>;

export function createKeyboardShortcutStore() {
    const keyboardShortcutStoreConfig: StateCreator<KeyboardShortcutState> = (set) => ({
        activeShortcuts: [],

        showShortcutBar(key: KeyboardShortcutBarType) {
            set(
                (state): KeyboardShortcutState => ({
                    ...state,
                    activeShortcuts: shortcutsType[key] ?? [],
                }),
            );
        },

        hideShortcutBar() {
            set(
                (state): KeyboardShortcutState => ({
                    ...state,
                    activeShortcuts: [],
                }),
            );
        },
    });

    return create<KeyboardShortcutState>()(keyboardShortcutStoreConfig);
}
