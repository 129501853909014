export enum DialogType {
    IMPORT_PART = 1,
    PUBLISH_PART = 2,
    PART_UPDATES = 3,
    CREATE_ORGANIZATION = 4,
    ORGANIZATION_ADD_MEMBERS = 5,
    SHADOWBAN = 6,
    USER_PLANS_AND_PAYMENTS = 7,
    FOLLOWERS = 8,
    FOLLOWING = 9,
    IMPORT_DATASHEET_PART = 10,
    NEW_PROJECT = 11,
    CHOOSE_YOUR_PLAN = 12,
    RESTORE_PROJECT = 13,
    JEP30 = 14,
    API_KEYS = 15,
    AUTO_LAYOUT_ANNOUNCEMENT = 16,
    EXPORT_MANUFACTURING_FILES_DIALOG = 17,
    FREE_PLAN_ANNOUNCEMENT = 18,
}
