// eslint-disable-next-line import/order
import {EditorModes} from "@buildwithflux/models";

// eslint-disable-next-line import/no-webpack-loader-syntax
import Comlink from "comlink-loader-webpack5!./pcbDrcCopperOverlapWorker";

import {IDrcInputs, IDrcValidator} from "../../types";

import {serializeInputs} from "./serde";
import {ValidatorOutput} from "./types";

export class OverlappingCopperValidator implements IDrcValidator {
    problemTypeKey = "overlapping_copper";
    problemLabel = "Overlapping Copper";
    problemDescription = "Reports copper elements that are shorting.";

    async checkForProblems(inputs: IDrcInputs) {
        try {
            const workerInputs = serializeInputs(inputs);

            const comlink = new Comlink();

            const {collisionKeys}: ValidatorOutput = await comlink.runCopperOverlapDrc(workerInputs);

            return {
                error: false as const,
                problemTypeKey: this.problemTypeKey,
                foundProblems: collisionKeys.map((ck) => ({
                    problemTypeKey: this.problemTypeKey,
                    key: `${this.problemTypeKey}_${ck}`,
                    affectedItems: ck.split("+").map((uid) => ({type: "pcbLayoutNode" as const, uid})),
                    affectedViews: [EditorModes.pcb],
                })),
            };
        } catch (e) {
            return {
                error: `Copper overlap error:${(e as any)?.message} `,
                problemTypeKey: this.problemTypeKey,
                foundProblems: [],
            };
        }
    }
}
