import {IProblem} from "@buildwithflux/models";
import {produce} from "immer";
import {create} from "zustand";

import {IDrcValidator} from "./../../drc_validator/types";

export interface IProblemsStore {
    checksInProgress: Record<string, boolean>;
    detectedProblems: Record<string, IProblem>;

    // Errors that occured during DRC computation (as in, the DRC service failed to compute the DRC)
    detectedErrors: Record<string, string>;

    validators: IDrcValidator[];

    runDrcCheck: (checkKey: string) => (detectedProblem: IProblem[], detectedError: string | false) => void;

    updateDrcResults: (
        detectedProblems: Record<string, IProblem>,
        detectedErrors: Record<string, string>,
        validators: IDrcValidator[],
    ) => void;
}

export const useProblemsStore = create<IProblemsStore>()((set) => ({
    checksInProgress: {},
    detectedProblems: {},
    detectedErrors: {},
    processedData: null,
    validators: [],

    updateDrcResults(detectedProblems, detectedErrors, validators) {
        set(
            produce((state: IProblemsStore): void => {
                state.detectedProblems = detectedProblems;
                state.detectedErrors = detectedErrors;
                state.validators = validators;
            }),
        );
    },

    runDrcCheck(problemTypeKey: string) {
        // Helper that removes existing problem entries for this problem type, from the
        // problems store
        const cleanupExistingProblems = (state: IProblemsStore) => {
            // Drop existing problems for this problem type
            for (const key in state.detectedProblems) {
                const previousProblem = state.detectedProblems[key];
                if (previousProblem?.problemTypeKey === problemTypeKey) {
                    delete state.detectedProblems[previousProblem.key];
                }
            }
        };

        set(
            produce((state: IProblemsStore): void => {
                state.checksInProgress[problemTypeKey] = true;
            }),
        );

        return (detectedProblems: IProblem[], detectedError: string | false) => {
            set(
                produce((state: IProblemsStore): void => {
                    cleanupExistingProblems(state);

                    // Write new problems for this problem type
                    for (const detectedProblem of detectedProblems) {
                        if (detectedProblem.problemTypeKey === problemTypeKey) {
                            state.detectedProblems[detectedProblem.key] = detectedProblem;
                        }
                    }

                    state.detectedErrors[problemTypeKey] = detectedError as string; // What if detectedError is false?
                    state.checksInProgress[problemTypeKey] = false;
                }),
            );
        };
    },
}));

export function useActiveProblemsCount() {
    return useProblemsStore((s) => Object.keys(s.detectedProblems).length + Object.keys(s.detectedErrors).length);
}

export function useProblems() {
    return useProblemsStore((s) => s.detectedProblems);
}

export function useProblem(key: string) {
    return useProblemsStore((s) => s.detectedProblems[key]);
}

export function useErrors() {
    return useProblemsStore((s) => s.detectedErrors);
}
