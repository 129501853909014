import {
    ElementHelper,
    GROUND_TERMINAL_UID,
    IElementTerminalBareData,
    keySort,
    Orientation,
    vec2,
} from "@buildwithflux/core";
import {IElementData, IElementsMap, IRouteData, IVector2, IVertexMap} from "@buildwithflux/models";
import {Diff, diff} from "deep-diff";
import {Dictionary, groupBy, minBy, remove, uniq, uniqBy} from "lodash";

import {compareVec2} from "../../../helpers/float";
import {isTerminalEqual} from "../../../helpers/routeHelpers";

import Vertex from "./Vertex";
import {WireProjectionSimple} from "./WireProjectionSimple";
import {normalizeConnectedWires} from "./WiringNormalization";

// For brevity, since we use these values a lot:
const {HORIZONTAL, VERTICAL} = Orientation;

export type WireSegment = [Vertex, Vertex];

export type Wire = [Vertex, ...Vertex[]];

// `WireConnectionPoint` must always be complete, not sparse.
// Eg, if a vertex is connected to a terminal, then both .vertex and .terminal must
// be present, not just one or the other. But if a vertex is not connected to a
// terminal, only .vertex should be present.
//
// TODO it would be nice to type this more strongly, but the result isn't super ergonomic
// (and is a bit dangerous because it would require tests like ('terminal' in point)
// and TS allows point.terminal to be `undefined` instead of unset)
//
// In the meantime, the valid states of this type are:
//
// - A plain canvas position:           {position}
// - A vertex with no linked terminal:  {position, vertex}
// - A terminal with no linked vertex:  {position, terminal}
// - A terminal and its linked vertex:  {position, terminal, vertex}
// - A segment of two vertices:         {position, segment}
export type WireConnectionPoint = {
    position: IVector2;
    terminal?: IElementTerminalBareData;
    vertex?: Vertex;
    // NOTE: This is a subset of SegmentSubject. We want to avoid the dependency
    // on the UI.
    segment?: [string, string];
};

export type WireProjectionVertex = {direction?: Orientation; position: IVector2};

export type VertexUpdate = Diff<IVertexMap, IVertexMap>;
export type RouteUpdate = Diff<Dictionary<IRouteData>, Dictionary<IRouteData>>;

export type WireProjectionAlgorithm = {
    project: (from: WireConnectionPoint, to: WireConnectionPoint) => WireProjectionVertex[];
};

export default class Wiring {
    public readonly vertices: Map<string, Vertex> = new Map();
    public lastVerticesCommitted: IVertexMap = {};
    public lastRoutesCommitted: Dictionary<IRouteData> = {};
    // TODO this is an ugly wart. Wiring() should be operations on IDocumentData and have more direct
    // access to IDocumentData. It's great that we keep wiring operations mostly restricted to routeVertices,
    // but not great that we have this weird dependency. Will improve when we move Wiring() to be more
    // data-oriented.
    public getElements: () => IElementsMap;

    constructor(vertices: IVertexMap | undefined, elements: IElementsMap | (() => IElementsMap)) {
        this.getElements = typeof elements === "function" ? elements : () => elements;
        if (vertices) {
            this.loadVertices(vertices);
        }
    }

    // NOTE: this is slow because of deep-diff
    // TODO given that it would be ultra-helpful to keep a set of changed vertices for rendering, we
    // should reuse that logic for getting the updates here too (though they'll need to be separate
    // sets because they need to be reset at different times; but we can probably share the mechanism)
    public getVertexUpdates(): VertexUpdate[] | undefined {
        const frozen = this.serializeVertices();
        const changeSet = diff(this.lastVerticesCommitted, frozen);
        this.lastVerticesCommitted = frozen;
        return changeSet;
    }

    public getRouteUpdates(): RouteUpdate[] | undefined {
        const routeMap = {}; // Routes are deprecated. Don't generate them any more, but leave a stub to avoid changing too much in one PR.
        const changeSet = diff(this.lastRoutesCommitted, routeMap);
        this.lastRoutesCommitted = routeMap;
        return changeSet;
    }

    // NOTE: this is slow because of deep-diff
    // TODO: keep track of changes as they happen so that we do not need to diff
    public getWiringUpdates(): {
        routeUpdates?: RouteUpdate[];
        vertexUpdates?: VertexUpdate[];
    } {
        return {
            routeUpdates: this.getRouteUpdates(),
            vertexUpdates: this.getVertexUpdates(),
        };
    }

    // This needs to be kept in sync with the get route and vertex updates methods as it is used
    // by RouteLoader to re-initalise for diffing when changes are made.
    public updateLastCommited() {
        this.lastVerticesCommitted = this.serializeVertices();
        this.lastRoutesCommitted = {};
    }

    // TODO: this function does not attempt to preserve existing vertices like RouteLoader does.
    // We should make them the same, but for now this is a quick fix to be able to instantiate
    // Wiring() on a one-off basis.
    public loadVertices(rawVertices: IVertexMap) {
        // TODO: sort order?
        const vertexList = Object.values(rawVertices)
            .sort((a, b) => a.id?.localeCompare(b.id))
            .filter((v) => !!v.position)
            .map((vertex) => {
                const newVertex = new Vertex(vertex.position, vertex.id);
                newVertex.terminal = vertex.terminal;
                return newVertex;
            });

        for (const vertex of vertexList) {
            this.vertices.set(vertex.id, vertex);
        }

        for (const vertex of vertexList) {
            vertex.connectedVertices = Object.entries(rawVertices[vertex.id]!.connectedVertices ?? [])
                .filter(([connectedVertexId, _]) => this.vertices.has(connectedVertexId))
                .map(([connectedVertexId, connectionType]) => {
                    return {vertex: this.vertices.get(connectedVertexId)!, type: connectionType};
                });
        }

        this.updateLastCommited();
    }

    public disconnectElements(elementUids: string[]) {
        const elementUidsSet = new Set(elementUids);
        const affectedVertices: Vertex[] = [];
        this.vertices.forEach((vertex) => {
            if (vertex.terminal && elementUidsSet.has(vertex.terminal.element_uid)) {
                vertex.terminal = null;
                affectedVertices.push(vertex);
            }
        });
        return affectedVertices;
    }

    public removeWireSegments(segmentsToRemove: WireSegment[]) {
        const affectedVertices: Vertex[] = [];
        segmentsToRemove.forEach(([from, to]) => {
            this.disconnectVertices(from, to);
            affectedVertices.push(from, to);
        });
        // Remove any vertices that no longer have any connections:
        this.filterVertices((v) => !v.connectedVertices.length).forEach((vertex) => {
            this.vertices.delete(vertex.id);
        });
        affectedVertices.push(...this.normalizeConnectedNets(affectedVertices.filter((v) => this.vertices.has(v.id))));
        return uniqBy(affectedVertices, (v) => v.id);
    }

    /** Safely disconnect and remove specified vertices */
    public removeVertices(vertices: Vertex[]) {
        for (const vertex of vertices) {
            // Important: we clone connectedVertices with `...` before iterating below,
            // because `disconnectVertices` mutates the source array during iteration.
            for (const connection of [...vertex.connectedVertices]) {
                this.disconnectVertices(vertex, connection.vertex);
            }
            this.vertices.delete(vertex.id);
        }
    }

    /** The main wire creation operation. Places a wire from the last-clicked routing
     * point to the just-clicked one, keeping everything well-formed.
     */
    public placeWire(
        from: WireConnectionPoint,
        to: WireConnectionPoint,
        projectionAlgo = new WireProjectionSimple(),
        opts: {normalize?: boolean} = {},
    ): [Vertex[], Vertex] {
        let affectedVertices: Vertex[] = [];

        const fromVertex = this.ensureVertexAtConnectionPoint(from);
        affectedVertices.push(fromVertex);

        // Connect projected vertice(s).
        const projectedRoute = projectionAlgo.project(from, to);
        let prev = fromVertex;
        for (const {direction, position} of projectedRoute.slice(1, -1)) {
            const vertex = this.addVertex(position);
            this.connectVertices(prev, vertex, direction);
            affectedVertices.push(vertex);
            prev = vertex;
        }

        let toVertex = this.ensureVertexAtConnectionPoint(to);

        // Connect the end vertex to the previous one.
        this.connectVertices(prev, toVertex, projectedRoute.at(-1)?.direction);
        affectedVertices.push(toVertex);

        if (opts.normalize ?? true) {
            affectedVertices = this.normalizeConnectedWires(toVertex);
        }

        // Normalization can change which vertices exist. Get the nearest one if it changes:
        toVertex = minBy(
            affectedVertices.filter((v) => this.vertices.has(v.id)),
            (v) => vec2.distance(v.position, to.position),
        )!;

        return [affectedVertices, toVertex];
    }

    public getAllNetVerticesAcrossPortals(vertex: Vertex) {
        function makePortalGroupId(element: IElementData) {
            // Note: this uses equivalent logic to the analogous code in netMapping.ts
            return ElementHelper.isPortal(element)
                ? element.label ?? ""
                : ElementHelper.isGroundElement(element)
                ? GROUND_TERMINAL_UID
                : undefined;
        }

        const netVertices = this.getAllConnectedVertices(vertex);
        const elements = this.getElements();
        const portals = Object.values(netVertices)
            .map((v) => elements[v.terminal?.element_uid!])
            .filter(
                (elem): elem is IElementData =>
                    !!elem && (ElementHelper.isPortal(elem) || ElementHelper.isGroundElement(elem)),
            );

        // TODO this needs to recurse! Currently it'll only detect other subnets directly connected to this subnet's
        // portals (which is most cases!), but it won't detect cases where those subnets are connected to
        // different subnets through differently-named portals.
        if (portals.length) {
            const verticesByElementUid = groupBy([...this.vertices.values()], (v) => v.terminal?.element_uid);
            const allPortalsByGroup = groupBy(Object.values(elements), (elem) => makePortalGroupId(elem));
            for (const portal of portals) {
                const portalGroupId = makePortalGroupId(portal);
                if (!(portalGroupId && portalGroupId in allPortalsByGroup)) continue;
                for (const other of allPortalsByGroup[portalGroupId]!) {
                    if (other.uid === portal.uid) continue;
                    for (const otherVertex of verticesByElementUid[other.uid] ?? []) {
                        netVertices.push(...this.getAllConnectedVertices(otherVertex));
                    }
                }
            }
        }
        return uniq(netVertices);
    }

    /** Get all vertex connected (directly or indirectly) to the given vertex, but only
     * traversing until we run into a vertex that doesn't match the matching() condition.
     * If `includeUnmatchingLeafVertices` is true, also return any unmatching vertices
     * directly connected to the matching vertices.
     *
     * Also include the given vertex in the returned list according to the same logic.
     *
     * Does not cross portals (use `getAllNetVerticesAcrossPortals` for that).
     */
    public getAllConnectedVertices(
        vertex: Vertex,
        matching?: (v: Vertex) => unknown,
        includeUnmatchingLeafVertices = false,
        visited: {[uid: string]: Vertex} = {},
    ) {
        if (matching && !matching(vertex)) {
            return includeUnmatchingLeafVertices ? [vertex] : [];
        }
        visited[vertex.id] = vertex;
        const found = [vertex];
        // TODO in future, connectedVertices should just be ids+orientations, to be looked up
        // on routeManager.vertices, so that vertices are just plain data, and everything is controlled
        // by RouteManager (or NetsHandler in future).
        for (const connection of vertex.connectedVertices) {
            if (connection.vertex.id in visited) continue;
            found.push(
                ...this.getAllConnectedVertices(connection.vertex, matching, includeUnmatchingLeafVertices, visited),
            );
        }
        return found;
    }

    /** Return a list of wires (each wire is a chain of singly-connected wire segments up until a
     * branchpoint or terminal) connected to the given vertex. If the vertex has 2 outgoing
     * connections, it is considered a single wire (i.e. not two wires separated by the vertex),
     * unless it's a terminal-connected vertex. If the vertex is 3+-way connected, then each
     * outgoing connection is a separate wire.
     *
     * For consistency, each wire includes the given vertex too (in the middle if it's a mid-wire
     * vertex)
     *
     * Note: for now, this only returns a valid result if the input vertex is a wire endpoint (i.e.
     * a terminal, loose endpoint, or branchpoint). The given vertex is always the first
     * entry.
     */
    public getDirectlyConnectedWires(vertex: Vertex): Wire[] {
        function isBranchVertex(vertex: Vertex) {
            return vertex.terminal || vertex.connectedVertices.length > 2;
        }

        let wires: Wire[] = [];
        for (const connection of vertex.connectedVertices) {
            let wire = this.getAllConnectedVertices(connection.vertex, (v) => !isBranchVertex(v), true);
            // TODO very bad, will fail if starting from a non-branchpoint vertex:
            wire = wire.filter((v) => v !== vertex);
            if (!wire[0]) {
                // Should never get here, since at least the connected vertex must always be
                // included. Fail loudly if we do.
                throw new Error(
                    "getAllConnectedVertices returned empty list even though includeUnmatchingLeafVertices=true",
                );
            }
            wires.push([vertex, ...wire]);
        }

        if (!isBranchVertex(vertex) && wires.length === 2) {
            const leading = wires[0]!.slice(1).reverse() as [Vertex, ...Vertex[]];
            const trailing = wires[1]!;
            wires = [[...leading, ...trailing]];
        }

        return wires;
    }

    public filterVertices(predicate: (v: Vertex) => unknown) {
        // `unknown`, not `boolean` to allow for truthy/falsy while still being typesafe (prevent using result other than as a condition)
        const vertices: Vertex[] = [];
        for (const vertex of this.vertices.values()) {
            if (predicate(vertex)) vertices.push(vertex);
        }
        return vertices;
    }

    public findVertex(predicate: (v: Vertex) => unknown) {
        // `unknown`, not `boolean` to allow for truthy/falsy while still being typesafe
        for (const vertex of this.vertices.values()) {
            if (predicate(vertex)) return vertex;
        }
        return undefined;
    }

    public getVertices(uids: [string]): [Vertex];
    public getVertices(uids: [string, string]): [Vertex, Vertex];
    public getVertices(uids: string[]) {
        return uids.map((uid) => this.vertices.get(uid)).filter((v) => !!v) as Vertex[];
    }

    public addVertex(position: IVector2) {
        const vertex = new Vertex(position);
        this.vertices.set(vertex.id, vertex);
        return vertex;
    }

    /**
     * Given a set of vertices, normalize all nets connected to any of those vertices.
     */
    public normalizeConnectedNets(vertices: Vertex[]) {
        const seenVertices = new Set<string>();
        const uniqueNetVertices = new Set<Vertex>();
        for (const vertex of vertices) {
            if (seenVertices.has(vertex.id)) continue;
            uniqueNetVertices.add(vertex);
            const connectedVertices = this.getAllConnectedVertices(vertex);
            connectedVertices.forEach((v) => seenVertices.add(v.id));
        }
        const affectedVertices: Vertex[] = [];
        for (const vertex of uniqueNetVertices) {
            affectedVertices.push(...this.normalizeConnectedWires(vertex));
        }
        return uniqBy(affectedVertices, (v) => v.id);
    }

    /** Given a vertex, normalize all the wires in the same net (but not across portals). */
    public normalizeConnectedWires(vertex: Vertex) {
        return normalizeConnectedWires(this, vertex);
    }

    /** Low-level vertex disconnect. Leaves the datastructure consistent (i.e. removes both
     * sides of the double-link) but doesn't attempt to clean up high-level wire structure
     * like adjacent wire segments etc.
     */
    public disconnectVertices(from: Vertex, to: Vertex) {
        remove(from.connectedVertices, (v) => v.vertex === to);
        remove(to.connectedVertices, (v) => v.vertex === from);
    }

    /** Low-level vertex connection. Leaves the datastructure in consistent state but doesn't do
     * high-level things like adding corners, merging vertices, etc. Leaves vertex count unchanged,
     * and fails hard on illegal operations.
     * Explicitly specify orientation=undefined for orientation to be selected automatically.
     */
    public connectVertices(from: Vertex, to: Vertex, orientation: Orientation | undefined) {
        const direction = vec2.sub(from.position, to.position);
        orientation =
            orientation ??
            // JS div-by-0 gives Infinity so the below works.
            (Math.abs(direction.x / direction.y) > 1 ? HORIZONTAL : VERTICAL);

        if (!from.connectedVertices.map(({vertex: v}) => v.id).includes(to.id)) {
            from.connectedVertices.push({vertex: to, type: orientation});
        }
        if (!to.connectedVertices.map(({vertex: v}) => v.id).includes(from.id)) {
            to.connectedVertices.push({vertex: from, type: orientation});
        }
    }

    /** Convert a single segment into multiple connected segments, adding a vertex at each midpoint.
     * Return a list of Vertex instances, one for each given midpoint, in the same order as the
     * provided midpoints (however, the midpoints are connected in ascending order between `from`
     * and `to`, so the resulting segment does not contain overlaps, so long as the provided
     * midpoints are inside the endpoints). The returned vertices are not necessarily unique: if any
     * positions are equal, then the same vertex is re-used.
     */
    public splitSegment(from: Vertex, to: Vertex, midPoints: [IVector2]): [Vertex];
    public splitSegment(from: Vertex, to: Vertex, midPoints: [IVector2, IVector2]): [Vertex, Vertex];
    public splitSegment(from: Vertex, to: Vertex, midPoints: IVector2[]) {
        const connectionType = from.connectedVertices.find((v) => v.vertex === to)?.type;
        if (!connectionType) {
            throw new Error("Cannot split nonexistent connection");
        }
        const midVertices = Array(midPoints.length).fill(undefined);
        let prev = from;
        this.disconnectVertices(from, to);
        // Sorting by "scalar projection" would be more correct in that it would ensure
        // ascending order of points outside the endpoints. But we're not trying to
        // handle those cases, so distance is fine.
        const sortedMidpoints = midPoints
            .map((p, idx) => ({point: p, idx}))
            .sort(keySort((p) => vec2.distance(from.position, p.point)));
        for (const {point, idx} of sortedMidpoints) {
            const vertex = compareVec2(prev.position, point)
                ? prev
                : compareVec2(to.position, point)
                ? to
                : this.addVertex({...point});
            if (vertex !== prev) {
                this.connectVertices(prev, vertex, connectionType);
            }
            midVertices[idx] = vertex;
            prev = vertex;
        }
        if (prev !== to) {
            this.connectVertices(prev, to, connectionType);
        }
        return midVertices as Vertex[];
    }

    public serializeVertices(): IVertexMap {
        return Object.fromEntries([...this.vertices.values()].map((vertex) => [vertex.id, vertex.serialize()]));
    }

    /** Make a connection point at position constrained to the given subject, or canvas if
     * not specified, keeping the datastructure well-formed.
     */
    private ensureVertexAtConnectionPoint(at: WireConnectionPoint) {
        let vertex: Vertex | undefined;
        if (at.vertex) {
            vertex = at.vertex;
        } else if (at.terminal) {
            // TODO we shouldn't need to look up vertex here anymore, because we require callers to specify
            // the vertex if it exists, so it'll match the previous `at.vertex` condition. But we don't
            // have complete guarantees of that yet, so keep this here.
            vertex = this.findVertex((v) => v.terminal && isTerminalEqual(v.terminal, at.terminal!));
            if (!vertex) {
                vertex = this.addVertex(at.position);
                vertex.terminal = at.terminal;
            }
        } else if (at.segment) {
            const vertices = this.getVertices(at.segment) as [Vertex, Vertex];
            [vertex] = this.splitSegment(...vertices, [at.position]);
        } else {
            vertex = this.addVertex(at.position);
        }
        return vertex;
    }
}
