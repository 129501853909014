import {PcbRuleSetsMap, PcbRulesMap} from "@buildwithflux/core";
import createCachedSelector from "re-reselect";

import type {IApplicationState} from "../../../../state";

// NOTE: needs to be used with shallow comparison for proper memoization
const selectNodeObjectSpecificRules = createCachedSelector(
    (state: IApplicationState) => state.document?.pcbLayoutNodes,
    (_state: IApplicationState, nodeUid: string) => nodeUid,
    (pcbLayoutNodes, nodeUid) => pcbLayoutNodes?.[nodeUid]?.pcbNodeRuleSet || ({} as PcbRulesMap),
)((_state: IApplicationState, nodeUid: string) => nodeUid);

const selectGlobalPcbLayoutRuleSet = createCachedSelector(
    (state: IApplicationState) => state.document?.pcbLayoutRuleSets,
    (_state: IApplicationState, ruleUid: string) => ruleUid,
    (pcbLayoutRuleSets, ruleUid) => pcbLayoutRuleSets?.[ruleUid],
)((_state: IApplicationState, ruleUid: string) => ruleUid);

// NOTE: needs to be used with shallow comparison for proper memoization
const selectGlobalPcbLayoutRules = (state: IApplicationState): PcbRuleSetsMap => {
    const globalRules: PcbRuleSetsMap = {};
    const rules = state.document?.pcbLayoutRuleSets;

    Object.entries(rules || {}).forEach(([uid, rule]) => {
        if ("referenceOfDocumentUid" in rule) {
            globalRules[uid] = rule;
        }
    });
    return globalRules;
};

const selectPcbLayoutRuleSets = (state: IApplicationState) => state.document?.pcbLayoutRuleSets;

const selectPcbLayoutRuleSetDisabled = (ruleUid: string) => {
    return (state: IApplicationState): boolean => {
        const rule = state.document?.pcbLayoutRuleSets[ruleUid];

        if (rule && rule.referenceOfDocumentUid) {
            if (rule.disabled !== undefined) {
                return rule.disabled;
            }
        }

        return false;
    };
};

const pcbLayoutRulesSelectors = {
    selectGlobalPcbLayoutRuleSet,
    selectNodeObjectSpecificRules,
    selectGlobalPcbLayoutRules,
    selectPcbLayoutRuleSets,
    selectPcbLayoutRuleSetDisabled,
};

export default pcbLayoutRulesSelectors;
