import {createPcbLayoutNodeExpressionContext, evaluateMechanicalValue} from "@buildwithflux/core";
import {CameraMode, IDocumentData, PcbNodeTypes} from "@buildwithflux/models";
import {assertUnreachable} from "@buildwithflux/shared";
import {cloneDeep, debounce, omit, isEmpty, keyBy} from "lodash";
import {Vector2} from "three";
import {combine, createJSONStorage, persist, StateStorage} from "zustand/middleware";
import {createWithEqualityFn} from "zustand/traditional";

import R from "../../../resources/Namespace";
import {FluxLogger} from "../../storage_engine/connectors/LogConnector";

import {refEqualityWithLogging} from "./helpers";
import {
    LayerViewState,
    PcbEditorUiActions,
    PcbEditorUiPersistentState,
    PcbEditorUiState,
    PcbEditorUiTransientState,
} from "./PcbEditorUiStore.types";

const debouncedSave = debounce((name: string, value: string) => {
    window.sessionStorage.setItem(name, value);
}, R.behaviors.storage.writeDelay);

const storage: StateStorage = {
    removeItem(name: string): void {
        window.sessionStorage.removeItem(name);
    },
    getItem: (name: string): string | null => {
        return window.sessionStorage.getItem(name);
    },
    setItem: async (name: string, value: string): Promise<void> => {
        debouncedSave(name, value);
    },
};

const DefaultPersistentState: PcbEditorUiPersistentState = Object.freeze({
    cameraMode: CameraMode.two_d,
    flippedCamera: false,
    layerViewState: LayerViewState.closed,
    pcbEnabledSnapping: true,
    pcbEnabledMeasurements: true,
    pcbThreeDModelsVisibility: true,
});

const DefaultTransientState: PcbEditorUiTransientState = {
    interactionAborted: false,
    interactionState: {state: "DEFAULT"},
    disableSelectionCommit: false,
    routingState: undefined,
    multiRoutingState: undefined,
    disableMultiRouting: false,
    multiRoutingCache: undefined,
    selectedPolygonToEditUid: undefined,
    polygonDrawState: undefined,
    threeDSceneContentGroupMesh: null,
    projectedHumanizedTraceWidth: undefined,
    selectedHumanizedTraceWidth: undefined,
    activeTouchpointContext: null,
    measuringStartPoint: new Vector2(),
    pcbEditorR3FStore: null,
    previewSegments: [],
    placeholderSegments: {},
    multiRoutingHover: false,
    smartViaConfigsCache: {},
    autoLayoutIterationData: undefined,
};

const DefaultState: PcbEditorUiState = Object.freeze({
    ...DefaultPersistentState,
    ...DefaultTransientState,
});

export const selectProjectedTraceWidth = (
    state: PcbEditorUiState,
    documentData: IDocumentData | null,
    ignoreSelected = false,
    overrideValue?: string,
): number => {
    const humanizedWidth =
        overrideValue ??
        (ignoreSelected
            ? state.projectedHumanizedTraceWidth
            : state.selectedHumanizedTraceWidth ?? state.projectedHumanizedTraceWidth);

    if (documentData && humanizedWidth) {
        const pcbLayoutNodeExpressionContext = createPcbLayoutNodeExpressionContext(documentData, FluxLogger);

        const widthInMeters: number = evaluateMechanicalValue(
            humanizedWidth,
            true,
            pcbLayoutNodeExpressionContext,
        ).toNumber("m");

        return widthInMeters;
    }

    return 0.000254; // Fallback to default - aka "10m;
};

export type PcbEditorUiStore = PcbEditorUiActions & PcbEditorUiState;

const _usePcbEditorUiStore = createWithEqualityFn<PcbEditorUiStore>()(
    persist(
        combine(cloneDeep(DefaultState), (set, get) => ({
            setThreeDSceneContentGroupMesh(group) {
                set({threeDSceneContentGroupMesh: group});
            },
            interact(interaction) {
                const currentState = get().interactionState.state;
                switch (interaction) {
                    case "START_DRAGGING":
                        if (currentState === "DEFAULT") {
                            set({interactionState: {state: "DRAGGING"}});
                        }
                        break;
                    case "START_PANNING":
                        if (currentState === "DEFAULT") {
                            set({interactionState: {state: "PANNING"}});
                        } else if (currentState === "ROUTING") {
                            set({interactionState: {state: "PANNING_PAUSEDROUTING"}});
                        } else if (currentState === "MEASURING") {
                            set({interactionState: {state: "PANNING_PAUSEDMEASURING"}});
                        } else if (currentState === "POLYGON_DRAW") {
                            set({interactionState: {state: "PANNING_PAUSEDPOLYGON_DRAW"}});
                        }
                        break;
                    case "START_MEASURING":
                        set({interactionState: {state: "MEASURING"}});
                        break;
                    case "DISABLE":
                        set({interactionState: {state: "DISABLED"}});
                        break;
                    case "END_INTERACTION":
                        if (currentState === "PANNING_PAUSEDROUTING") {
                            set({interactionState: {state: "ROUTING"}});
                        } else if (currentState === "PANNING_PAUSEDMEASURING") {
                            set({interactionState: {state: "MEASURING"}});
                        } else if (currentState === "PANNING_PAUSEDPOLYGON_DRAW") {
                            set({interactionState: {state: "POLYGON_DRAW"}});
                        } else {
                            set({interactionState: {state: "DEFAULT"}});
                        }
                        break;
                    case "ABORT_INTERACTION":
                        set({
                            // We don't clean up selectedHumanizedTraceWidth here to avoid race conditions,
                            // we do that during start instead
                            interactionState: {state: "DEFAULT"},
                            routingState: undefined,
                            multiRoutingState: undefined,
                            polygonDrawState: undefined,
                            interactionAborted: true,
                            disableMultiRouting: false,
                        });
                        break;
                    default:
                        assertUnreachable(interaction);
                }
            },
            resetAbort() {
                set({interactionAborted: false});
            },
            setCameraMode(mode) {
                set({cameraMode: mode});
            },
            setFlippedCamera(flip) {
                set({flippedCamera: flip});
            },
            setRoutingState(
                position,
                lastVertexId?,
                lastNodeId?,
                netId?,
                layer?,
                flip?,
                freeRoutingMode?,
                snapToSegment?,
                routeFromSegment?,
            ) {
                set((state) => {
                    const routingStart = netId && lastVertexId && state.interactionState.state !== "ROUTING";
                    const routingEnd = !netId;
                    return {
                        ...state,

                        // On routing start, reset the trace width info
                        projectedHumanizedTraceWidth: routingStart ? undefined : state.projectedHumanizedTraceWidth,
                        selectedHumanizedTraceWidth: routingStart ? undefined : state.selectedHumanizedTraceWidth,

                        interactionState: netId && lastVertexId ? {state: "ROUTING"} : {state: "DEFAULT"},

                        multiRoutingState: routingEnd ? undefined : state.multiRoutingState,
                        disableMultiRouting: routingEnd ? false : state.disableMultiRouting,

                        routingState:
                            netId && lastVertexId && lastNodeId && layer
                                ? {
                                      netId,
                                      position,
                                      lastVertexId,
                                      lastNodeId,
                                      layer,
                                      flip: Boolean(flip),
                                      freeRoutingMode: Boolean(freeRoutingMode),
                                      snapToSegment: snapToSegment ?? null,
                                      routeFromSegment: routeFromSegment ?? null,
                                      snappedVertexUid: state.routingState?.snappedVertexUid ?? null,
                                      snappedPos: state.routingState?.snappedPos ?? null,
                                  }
                                : undefined,
                    };
                });
            },
            setRoutingSnap(snappedVertexUid, snappedPos) {
                set((state) => ({
                    ...state,
                    routingState: state.routingState
                        ? {...state.routingState, snappedVertexUid, snappedPos}
                        : state.routingState,
                }));
            },
            setMultiRoutingState(multiRoutingState) {
                set((state) => {
                    const routingStarted = multiRoutingState?.state === "MULTIROUTING";
                    return {
                        ...state,
                        multiRoutingState,
                        // HACK: we want to start with the state flipped in multi tracing mode
                        routingState:
                            routingStarted && state.routingState
                                ? {...state.routingState, flip: true}
                                : state.routingState,
                    };
                });
            },
            setDisableMultiRouting(disableMultiRouting) {
                set((state) => ({...state, disableMultiRouting}));
            },
            toggleMultiRouting() {
                set((state) => ({...state, disableMultiRouting: !state.disableMultiRouting}));
            },
            setMultiRoutingCache(multiRoutingCache) {
                set((state) => ({...state, multiRoutingCache}));
            },
            setLayerViewState(state) {
                set({layerViewState: state});
            },
            setHumanizedProjectedTraceWidth(humanizedWidth) {
                set((state) => {
                    return {
                        ...state,
                        projectedHumanizedTraceWidth: humanizedWidth,
                    };
                });
            },
            setHumanizedSelectedTraceWidth(humanizedWidth) {
                set((state) => {
                    return {
                        ...state,
                        selectedHumanizedTraceWidth: humanizedWidth,
                    };
                });
            },
            setActiveTouchpointContext(activeTouchpointContext) {
                // When right clicking on a point multiple calls can be made from different nodes
                // We want to keep the one that is most important, aka the one the has more layers attached to it
                // Aka vias, then pads, then route segments, then midpoints :P
                const order = [PcbNodeTypes.via, PcbNodeTypes.pad, PcbNodeTypes.routeSegment];
                set((state) => {
                    let orderNew = activeTouchpointContext ? order.indexOf(activeTouchpointContext.nodeType) : 99;
                    let orderPrev = state.activeTouchpointContext
                        ? order.indexOf(state.activeTouchpointContext.nodeType)
                        : 99;
                    if (activeTouchpointContext && activeTouchpointContext.edge === "midpoint") {
                        orderNew++;
                    }
                    if (state.activeTouchpointContext && state.activeTouchpointContext.edge === "midpoint") {
                        orderPrev++;
                    }

                    if (activeTouchpointContext === null || orderNew <= orderPrev) {
                        return {...state, activeTouchpointContext};
                    } else {
                        return state;
                    }
                });
            },
            setPcbEditorR3FStore(pcbEditorR3FStore) {
                set((state) => ({...state, pcbEditorR3FStore}));
            },
            setPreviewSegments(previewSegments) {
                set((state) => ({...state, previewSegments}));
            },
            /**
             * It's important to append the placeholder segments instead
             * of replacing them. That's because we might be baking traces
             * for multiple routing interactions, which are queued, meaning
             * there is need for multiple sets of placeholders - rather than
             * just the placeholders after the last routing click interaction
             */
            appendPlaceholderSegments(placeholderSegmentArray) {
                set((state) => {
                    const placeholderSegments = {
                        ...(state.placeholderSegments ?? {}),
                        ...keyBy(placeholderSegmentArray, ({uid}) => uid),
                    };

                    return {
                        ...state,
                        placeholderSegments,
                    };
                });
            },
            removePlaceholderSegments: (uids: string[]) => {
                set((state) => {
                    // Can't remove anything if there are no placeholder segments so early exit
                    if (!state.placeholderSegments) {
                        return state;
                    }

                    const placeholderSegments = omit(state.placeholderSegments, uids);

                    return {
                        ...state,
                        placeholderSegments: isEmpty(placeholderSegments) ? null : placeholderSegments,
                    };
                });
            },
            setMultiRoutingHover(multiRoutingHover) {
                set((state) => ({...state, multiRoutingHover}));
            },
            setSmartViaConfigsCache(smartViaConfigsCache) {
                set((state) => ({...state, smartViaConfigsCache}));
            },
            setPcbEnabledSnapping(pcbEnabledSnapping) {
                set((state) => ({...state, pcbEnabledSnapping}));
            },
            setPcbEnabledMeasurements(pcbEnabledMeasurements) {
                set((state) => ({...state, pcbEnabledMeasurements}));
            },
            setPcbThreeDModelsVisibility(pcbThreeDModelsVisibility) {
                set((state) => ({...state, pcbThreeDModelsVisibility}));
            },
            addAutoLayoutIterationData(iteration) {
                set((state) => {
                    const iterations = state.autoLayoutIterationData
                        ? [...state.autoLayoutIterationData.iterations]
                        : [];

                    iterations.push(iteration);

                    return {
                        ...state,
                        autoLayoutIterationData: {
                            currentIterationIndex: state.autoLayoutIterationData?.currentIterationIndex,
                            iterations,
                        },
                    };
                });
            },
            setAutoLayoutIterationData(iteration) {
                set((state) => {
                    const iterations = state.autoLayoutIterationData
                        ? [...state.autoLayoutIterationData.iterations]
                        : [];

                    const index = iterations.findIndex((iter) => iter.hash === iteration.hash);

                    if (index < 0) return state;

                    iterations[index] = iteration;

                    return {
                        ...state,
                        autoLayoutIterationData: {
                            currentIterationIndex: state.autoLayoutIterationData?.currentIterationIndex,
                            iterations,
                        },
                    };
                });
            },
            setAutoLayoutCurrentIterationIndex(iterationIndex) {
                set((state) => ({
                    ...state,
                    autoLayoutIterationData: state.autoLayoutIterationData
                        ? {
                              ...state.autoLayoutIterationData,
                              currentIterationIndex: iterationIndex,
                          }
                        : undefined,
                }));
            },
            clearAutoLayoutData() {
                set((state) => ({
                    ...state,
                    autoLayoutIterationData: {
                        currentIterationIndex: undefined,
                        iterations: [],
                    },
                }));
            },
            setSelectedPolygonToEditUid(selectedPolygonToEditUid) {
                set((state) => ({...state, selectedPolygonToEditUid}));
            },
            startDrawingPolygon(position, netId, layer, startTouchpointId) {
                set((state) => ({
                    ...state,
                    interactionState: {state: "POLYGON_DRAW"},
                    polygonDrawState: {
                        mode: "draw",
                        points: [position],
                        netId,
                        layer: layer ?? "All",
                        startTouchpointId,
                    },
                }));
            },
            polygonDrawAddPoint(position) {
                set((state) => ({
                    ...state,
                    polygonDrawState:
                        state.polygonDrawState === undefined || state.polygonDrawState.mode !== "draw"
                            ? undefined
                            : {...state.polygonDrawState, points: [...state.polygonDrawState.points, position]},
                }));
            },
            polygonDrawRemoveLastPoint() {
                set((state) => {
                    if (
                        state.polygonDrawState === undefined ||
                        state.polygonDrawState.mode !== "draw" ||
                        state.polygonDrawState.points.length === 1
                    ) {
                        // If there are no points left, abort operation
                        return {
                            ...state,
                            interactionState: {state: "DEFAULT"},
                            polygonDrawState: undefined,
                        };
                    }

                    return {
                        ...state,
                        polygonDrawState:
                            state.polygonDrawState === undefined
                                ? undefined
                                : {...state.polygonDrawState, points: state.polygonDrawState.points.slice(0, -1)},
                    };
                });
            },
            stopDrawingPolygon() {
                set((state) => ({
                    ...state,
                    interactionState: {state: "DEFAULT"},
                    polygonDrawState: undefined,
                }));
            },
            startEditingPolygon(polygonNodeUid, draggedPoint, startingAbsolutePos) {
                set((state) => ({
                    ...state,
                    interactionState: {state: "POLYGON_DRAW"},
                    polygonDrawState: {
                        mode: "edit",
                        polygonNodeUid,
                        draggedPoint,
                        projectedAbsolutePos: startingAbsolutePos,
                    },
                }));
            },
            stopEditingPolygon() {
                set((state) => ({
                    ...state,
                    interactionState: {state: "DEFAULT"},
                    polygonDrawState: undefined,
                }));
            },
            setDisableSelectionCommit(disableSelectionCommit) {
                set((state) => ({...state, disableSelectionCommit}));
            },
        })),
        {
            partialize(state) {
                return {
                    ...omit(state, [
                        "interactionState",
                        "disableSelectionCommit",
                        "routingState",
                        "multiRoutingState",
                        "disableMultiRouting",
                        "multiRoutingCache",
                        "polygonDrawState",
                        "threeDSceneContentGroupMesh",
                        "interactionAborted",
                        "selectedHumanizedTraceWidth",
                        "projectedHumanizedTraceWidth",
                        "activeTouchpointContext",
                        "measuringStartPoint",
                        "pcbEditorR3FStore",
                        "previewSegments",
                        "placeholderSegments",
                        "multiRoutingHover",
                        "smartViaConfigsCache",
                        "autoLayoutIterationData",
                        "selectedPolygonToEditUid",
                    ]),
                };
            },
            name: "usePcbEditorUiStore",
            storage: createJSONStorage(() => storage),
        },
    ),
);

/**
 * Make usePcbEditorUiStore use custom comparison
 * @see https://github.com/pmndrs/zustand/issues/685
 */
// @ts-ignore: because of need for Object.assign below
export const usePcbEditorUiStore: typeof _usePcbEditorUiStore = <K>(
    selector: (state: PcbEditorUiState) => K,
    equalityFn?: (a: any, b: any) => boolean,
) => _usePcbEditorUiStore(selector, equalityFn || refEqualityWithLogging(selector));
// For getState and any other special zustand methods attached to function object
Object.assign(usePcbEditorUiStore, _usePcbEditorUiStore);
