import {OnboardingStep, SuccessfulStripeVerificationUrlParam} from "@buildwithflux/models";

import {InitializedStoreState, OnboardingStoreState} from "./types";

/**
 * Removes all search (query) parameters from the current browser location
 *
 * Accesses the global window object as as globalThis to work around
 * https://github.com/firebase/firebase-js-sdk/issues/7712
 * (That issue requires we test from Jest using the node environment, so window isn't available)
 */
export function removeAllOnboardingSearchParamsFromLocation() {
    const searchParams = getSearchParamsFromLocation();
    searchParams.delete(SuccessfulStripeVerificationUrlParam);
    globalThis.history?.replaceState(
        null,
        "",
        `${globalThis.location.pathname}${searchParams.toString() !== "" ? `?${searchParams.toString()}` : ""}`,
    );
}

/**
 * Gets the search (query) parameters from the current browser location
 *
 * Accesses the global window object as as globalThis to work around
 * https://github.com/firebase/firebase-js-sdk/issues/7712 That issue requires we test from Jest using the node
 * environment, so window isn't available)
 */
export function getSearchParamsFromLocation(): URLSearchParams {
    return new URLSearchParams(globalThis.location?.search ?? "");
}

/**
 * Narrowing type guard for store states
 */
export function onboardingDialogStateIsAtStep<
    Step extends OnboardingStep,
    State extends OnboardingStoreState & {step: Step},
>(state: OnboardingStoreState, step: Step): state is State {
    return state.step === step;
}

export function assertOnboardingDialogStateInitialized(
    state: OnboardingStoreState,
): asserts state is InitializedStoreState {
    if (!state.userUid) {
        throw new Error("Expected store to be initialized, but it has no userUid");
    }
    if (!state.userHandle) {
        throw new Error("Expected store to be initialized, but it has no userHandle");
    }
    if (!state.flow) {
        throw new Error("Expected store to be initialized, but it has no flow");
    }
    if (!state.completedSteps) {
        throw new Error("Expected store to be initialized, but it has no completedSteps");
    }
}
