import {ExperimentConfiguration, ExperimentConfigurationKind, ExperimentVariant} from "@buildwithflux/models";
import {stableProbability} from "@buildwithflux/plans";

export function experimentVariantFromConfiguration(
    uniqueIdentifier: string,
    configuration: ExperimentConfiguration,
): ExperimentVariant {
    switch (configuration.kind) {
        case ExperimentConfigurationKind.enum.on:
            return ExperimentVariant.enum.on;
        case ExperimentConfigurationKind.enum.off:
            return ExperimentVariant.enum.off;
        case ExperimentConfigurationKind.enum.ratio:
            return stableProbability(uniqueIdentifier, configuration.ratio)
                ? ExperimentVariant.enum.test
                : ExperimentVariant.enum.control;
    }
}
