import * as amplitude from "@amplitude/analytics-browser";
import {
    Account,
    AmountInDollars,
    documentIsPublished,
    DocumentUid,
    EditorModes,
    getAbsoluteProfileUrl,
    getPaymentPlanCategory,
    getStackupLayersAndViaConfigs,
    getViaSegmentCanonicalType,
    getViaSegmentFromType,
    IAssetData,
    inMemoryCacheStats,
    isTestAccount,
    IUserData,
    Organization,
    OrganizationUid,
    PaymentPlanCategory,
    PcbNodeTypes,
    PcbViaType,
    PerformanceBudgetViolationError,
    StackupInfo,
    trackedFlagsSchema,
    trackingEventActionsForAccounts,
    trackingEventActionsForCopilotCredits,
    trackingEventActionsForEnterprises,
    trackingEventActionsForNewProjectDialog,
    trackingEventActionsForOrganizations,
    trackingEventActionsForPartUpdate,
    TrackingEventContentType,
    trackingEventOrganizations,
    TrackingEventParameters,
    trackingEventParametersForAccount,
    TrackingEventPrimitiveParameterValue,
    traitsForOrganizationAsGroup,
    UserAnalyticsRepository,
    UserUid,
} from "@buildwithflux/core";
import {
    accountIsOrganization,
    ChatMessageContext,
    ChatMessageTool,
    CopilotModel,
    Enterprise,
    EnterpriseUid,
    ExperimentConfigurationKind,
    ExperimentVariant,
    IElementData,
    isPaidPaymentPlanCategory,
    OnboardingFlow,
    PlanEpoch,
} from "@buildwithflux/models";
import {
    getPlanEpochFromUserPrivateMetadata,
    getStateOfTrialSubscription,
    getTrialInfoFromUserPrivateMetadata,
    getTrialTypeFromUserPrivateMetadata,
} from "@buildwithflux/plans";
import {
    APP_VERSION,
    areWeInStorybook,
    areWeTestingWithJest,
    EnvironmentName,
    getEnvironmentName,
    isCypressTestUserAgent,
    isDevEnv,
    Logger,
    silentLogger,
} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import {AnalyticsBrowser, UserTraits as PossibleUserTraits} from "@segment/analytics-next";
import Cookie from "js-cookie";
import {Utm} from "utm-extractor";

import {currentAgentIsBot} from "../../helpers/isBot";
import {ReduxStoreService} from "../../redux/util/service";
import {CurrentUserService} from "../auth";
import {UseOrganizationStore} from "../auth/state/organization";
import {FeatureFlagsStore} from "../feature_flags/FeatureFlagsStore";
import {experimentVariantFromConfiguration} from "../feature_flags/variants";
import {usePcbEditorUiStore} from "../stores/pcb/PcbEditorUiStore";
import {PersistedDocumentUiStore} from "../stores/ui";

import {getIpAddress, isIpAddressBlocked} from "./analytics";
import {DeprecatedTrackingEvent, DeprecatedTrackingEvents} from "./common/DeprecatedTrackingEvents";
import {GeneralTrackingEvents} from "./common/GeneralTrackingEvents";
import {ObjectTypeTrackingEvents} from "./common/ObjectTypeTrackingEvents";
import {SurfaceBasedTrackingEvents} from "./common/SurfaceBasedTrackingEvents";
import {TrackingEvent} from "./common/TrackingEvents";
import {FluxLogger} from "./connectors/LogConnector";
import {logTimeToNextIdle} from "./helpers/logTimeToNextIdle";
import {pcbModeStats} from "./helpers/PcbModeStats";
import {schematicModeStats} from "./helpers/SchematicModeStats";

const analyticsEnabledEnvironments = [EnvironmentName.Release, EnvironmentName.Production, EnvironmentName.Main];

// IMPORTANT: Some Segment destinations (e.g., Canny) require name (which is the
// full name or handle) to be set otherwise identify events will be rejected
// NOTE: see also backend ISegmentUserTraits
type UserTraits = PossibleUserTraits & Required<Pick<PossibleUserTraits, "name">>;

let sessionStartTime: number | undefined;

function getSessionStartTime() {
    if (!sessionStartTime) {
        //session start time in Unix format
        sessionStartTime = Math.floor(Date.now() / 1000);
    }
    return sessionStartTime;
}

function defaultSegmentOptions(options?: {}) {
    return {
        ...options,
        context: {
            app: {version: APP_VERSION},
        },
        integrations: {
            Amplitude: {
                session_id: getSessionStartTime(),
            },
        },
    };
}

function getQueryParam(param: string) {
    const queryParams = [...new URLSearchParams(window.location.search)];
    return queryParams.filter(([k, v]) => k === param && v)?.map(([_k, v]) => v)[0];
}

const CannyIdentify = (...args: any[]) => {
    // @ts-ignore
    if (Canny) {
        // @ts-ignore
        return Canny("identify", ...args);
    }
};

const AlgoliaTrack = (
    event:
        | "clickedObjectIDsAfterSearch"
        | "clickedObjectIDs"
        | "convertedObjectIDsAfterSearch"
        | "convertedObjectIDs"
        | "clickedFilters"
        | "convertedFilters"
        | "viewedObjectIDs"
        | "viewedFilters",
    userUid: string | undefined,
    index: string,
    eventName: string,
    properties: {},
) => {
    // @ts-ignore
    if (window?.aa) {
        // @ts-ignore
        return window.aa(event, {
            userToken: userUid,
            index: index,
            eventName: eventName,
            ...properties,
        });
    }
};

let isAmplitudeInitialized = false;
/**
 * This is for logging events directly to Amplitude in our "CI runs" project.
 * Use SegmentTrack for the normal "Flux-App" amplitude project.
 *
 * @see https://app.amplitude.com/analytics/defygravity/connections/project/486809/sources/setup/SDK_TS?source=connections%20page:%20sources
 */
const AmplitudeTrack = (event: string, properties?: {}) => {
    if (!isAmplitudeInitialized) {
        amplitude.init("d91d534a3b0590ec874e0d649c28b793");
        isAmplitudeInitialized = true;
    }
    amplitude.track(event, properties);
};

const isAnalyticsEnabledEnvironment = () => {
    // env-var is set in CI on PRs with the label `analytics-enabled`, otherwise analytics would be disabled for PRs
    const analyticsEnabledOverride =
        typeof process !== "undefined" && process.env.REACT_APP_ANALYTICS_ENABLED === "true";
    return analyticsEnabledOverride || analyticsEnabledEnvironments.some((e) => e === getEnvironmentName());
};

// Sign In|Up Tracking

// need to be simple key/value pairs. No nested objects!!!
interface IEventParamsProp extends TrackingEventParameters {
    content_type?: TrackingEventContentType | TrackingEventPrimitiveParameterValue;
    content_id?: TrackingEventPrimitiveParameterValue;
    eventSource?: "frontend" | "backend";
    action?: TrackingEventPrimitiveParameterValue;
    surface?: TrackingEventPrimitiveParameterValue;
}

type CopilotChatNewMessageParams = {
    action: "branch_message" | "create_message";
    tools: ChatMessageTool[];
    model: CopilotModel;
    // Optional
    contexts?: ChatMessageContext[];
};
type CopilotChatAddToolParams = {
    action: "add_tool";
    tool: ChatMessageTool;
};
type CopilotChatChangeModelParams = {
    action: "change_model";
    model: CopilotModel;
};
type CopilotChatAddAttachmentParams = {
    action: "add_attachment";
    fileType: string;
};
type CopilotChatDraftMessageParams = {
    action: "draft_message_from_prompt";
    promptKey: string;
};
type CopilotChatActionSpecificParams =
    | CopilotChatNewMessageParams
    | CopilotChatAddToolParams
    | CopilotChatChangeModelParams
    | CopilotChatAddAttachmentParams
    | CopilotChatDraftMessageParams;

// For consolidation, to prevent typos. See uses.
type CopilotChatEventParams = {
    threadUid?: string;
} & CopilotChatActionSpecificParams;

export class AnalyticsStorage {
    private segment?: AnalyticsBrowser;
    private ipAddress?: string;
    private trackingErrorLogged = false;

    constructor(
        private readonly storeService: ReduxStoreService,
        private readonly currentUserService: CurrentUserService,
        private readonly userAnalyticsRepository: UserAnalyticsRepository,
        private readonly useOrganizationStore: UseOrganizationStore,
        private readonly documentService: DocumentService,
        private readonly persistedDocumentUiStore: PersistedDocumentUiStore,
        private readonly featureFlagsStore: FeatureFlagsStore,
        private readonly logger: Logger = silentLogger,
    ) {}

    public async trackAccountEvent(
        action: keyof typeof trackingEventActionsForAccounts,
        account: Account,
        additionalEventParameters: TrackingEventParameters = {},
    ) {
        if (accountIsOrganization(account)) {
            await this.trackOrganizationEvent(action, account, additionalEventParameters);
        } else {
            await this.trackUserEvent(action, account, additionalEventParameters);
        }
    }

    public async trackOrganizationEvent(
        action: keyof typeof trackingEventActionsForOrganizations | keyof typeof trackingEventActionsForAccounts,
        organization: Organization,
        additionalEventParameters: TrackingEventParameters = {},
    ) {
        await this.logEvent(trackingEventOrganizations, {
            action,
            ...trackingEventParametersForAccount(organization),
            ...additionalEventParameters,
        });
    }

    public async trackEnterpriseEvent(
        action: keyof typeof trackingEventActionsForEnterprises | keyof typeof trackingEventActionsForAccounts,
        enterprise: Enterprise,
        enterpriseOrganization: Organization | undefined,
        additionalEventParameters: TrackingEventParameters = {},
    ) {
        await this.logEvent(trackingEventOrganizations, {
            action,
            ...(enterpriseOrganization ? trackingEventParametersForAccount(enterpriseOrganization) : {}),
            ...trackingEventParametersForAccount(enterprise),
            ...additionalEventParameters,
        });
    }

    public async trackUserEvent(
        action: keyof typeof trackingEventActionsForAccounts,
        user: IUserData,
        additionalEventParameters: TrackingEventParameters = {},
    ) {
        await this.logEvent(ObjectTypeTrackingEvents.users, {
            action,
            ...trackingEventParametersForAccount(user),
            ...additionalEventParameters,
        });
    }

    public async trackCopilotCreditsEvent(
        action: keyof typeof trackingEventActionsForCopilotCredits,
        creditAccount?: Account,
        documentUid?: DocumentUid,
        additionalEventParameters: TrackingEventParameters = {},
    ) {
        await this.logEvent(SurfaceBasedTrackingEvents.copilotCredits, {
            action,
            credit_account_uid: creditAccount?.uid,
            ...(creditAccount ? trackingEventParametersForAccount(creditAccount) : {}),
            ...(documentUid ? {documentUid} : {}),
            ...additionalEventParameters,
        });
    }

    /**
     * NOTE: name starts with "log" so that it's discoverable in the IDE when ppl start at the more common "logEvent"
     */
    public async logCopilotChatEvent(params: CopilotChatEventParams) {
        let additionProperties = {};
        if (params.action === "create_message" || params.action === "branch_message") {
            additionProperties = {
                content_type: "message",
                selected_model: params.model,
                selected_contexts: params.contexts,
                selected_tools: params.tools,
            };
        } else if (params.action === "add_tool") {
            additionProperties = {
                content_type: "message_tool",
                tool_name: params.tool,
            };
        } else if (params.action === "change_model") {
            additionProperties = {
                content_type: "message_copilot_model",
                tool_name: params.model,
            };
        } else if (params.action === "add_attachment") {
            additionProperties = {
                content_type: "message_attachment",
                file_type: params.fileType,
            };
        } else if (params.action === "draft_message_from_prompt") {
            additionProperties = {
                content_type: "message",
                prompt_key: params.promptKey,
            };
        }

        return await this.logEvent(SurfaceBasedTrackingEvents.copilot, {
            action: params.action,
            surface: "copilot_chat",
            thread_uid: params.threadUid,
            ...additionProperties,
        });
    }

    public async trackPartUpdateEvent(
        action: keyof typeof trackingEventActionsForPartUpdate,
        userUid: string,
        additionalEventParameters: TrackingEventParameters = {},
    ) {
        await this.logEvent(DeprecatedTrackingEvents.partUpdate, {
            action,
            performed_by_user_uid: userUid,
            ...additionalEventParameters,
        });
    }

    public async trackNewProjectDialogEvent(
        action: keyof typeof trackingEventActionsForNewProjectDialog,
        organizationUid: OrganizationUid | undefined,
        enterpriseUid: EnterpriseUid | undefined,
        additionalEventParameters: TrackingEventParameters = {},
    ) {
        await this.logEvent(SurfaceBasedTrackingEvents.newProjectDialog, {
            action,
            organizationUid,
            enterpriseUid,
            ...additionalEventParameters,
        });
    }

    public async trackStripePaymentSuccess(priceUid: string) {
        await this.logEvent(ObjectTypeTrackingEvents.accounts, {
            action: trackingEventActionsForAccounts.paymentSuccess,
            priceUid,
        });
    }

    public async trackChoosePlan(planCategory: PaymentPlanCategory, price: AmountInDollars | undefined) {
        let bcPlanName: string = planCategory;

        if (planCategory === PaymentPlanCategory.enum.userLegacyFree) {
            bcPlanName = "free";
        } else if (planCategory === PaymentPlanCategory.enum.userLegacyPro) {
            bcPlanName = "pro";
        } else if (planCategory === PaymentPlanCategory.enum.userLegacyEdu) {
            bcPlanName = "educational";
        } else if (planCategory === PaymentPlanCategory.enum.userLegacyUltra) {
            bcPlanName = "ultra";
        }

        await this.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
            step: "choosePlan",
            action: bcPlanName,
            price,
            content_type: "onboardingDialog",
        });
    }

    /**
     * Log an event and associated data to all analytics systems.
     *
     * 1. Firebase
     * s. Segment (sends data to more systems, amplitude included)
     */
    public async logEvent(
        eventName: TrackingEvent | DeprecatedTrackingEvent,
        // need to be simple key/value pairs. No nested objects!!!
        eventParameters: IEventParamsProp = {},
    ) {
        // NOTE: analytics generated in a cypress run should be routed to a special amplitude project
        // QUESTION: should we also filter out cypress when running in localhost?
        if (!isCypressTestUserAgent() && !(await this.isAnalyticsEnabled(this.currentUserService.getCurrentUser()))) {
            if (isDevEnv() && !areWeTestingWithJest() && !areWeInStorybook()) {
                this.logger.debug(`Would have tracked ${eventName} event, but analytics are disabled in this context`, {
                    eventName,
                    eventParameters,
                });
            }
            return;
        }

        eventParameters = this.trackEventMetaData(eventParameters);

        // IMPORTANT: Cypress tests should never send (or identify) analytics anywhere other than this special Amplitude project
        if (isCypressTestUserAgent()) {
            AmplitudeTrack(eventName, eventParameters);
            return;
        }

        // IMPORTANT: Even just identifying a user counts towards our MTU in segment and downstream systems
        await this.identifyUser();

        if (eventName === GeneralTrackingEvents.pageView) {
            this.segmentPage("fluxApp", undefined, eventParameters);
        } else {
            this.segmentTrack(eventName, eventParameters);
        }
    }

    public logTimeToNextIdle(name: DeprecatedTrackingEvent): void {
        logTimeToNextIdle(this, name);
    }

    public async setUser(userData: IUserData) {
        if (isCypressTestUserAgent() || !(await this.isAnalyticsEnabled(userData))) {
            return;
        }

        // QUESTION: Why do we ignore anon users here but not in logEvent?  It also calls SegmentIdentify
        if (userData.isAnonymous) {
            return;
        }

        const userMetadata = this.getUserMetaData(userData);

        await this.segmentIdentify(userData.uid, userMetadata);

        // we save certain analytics metadata for better attributing frontend & backend events and user journeys
        await this.saveAnalyticsMetadata(userData.uid);

        let created = null;
        if (userData.created_at) {
            created = new Date(userData.created_at).toISOString();
        }

        const paidOrgMembershipsCount = this.useOrganizationStore
            .getState()
            .memberships.filter(
                (membership) =>
                    getPaymentPlanCategory(membership.organization) === PaymentPlanCategory.enum.organizationPaid,
            ).length;

        const paidUser = isPaidPaymentPlanCategory(getPaymentPlanCategory(userData));

        CannyIdentify({
            appID: process.env.REACT_APP_CANNY_APP_ID,
            user: {
                // Replace these values with the current user's data
                email: userData.email,
                name: userData.handle,
                id: userData.uid,

                // These fields are optional, but recommended:
                avatarURL: getAbsoluteProfileUrl(userData),
                monthlySpend: paidUser || paidOrgMembershipsCount > 0 ? 1 : 0,
                created,
            },
        });
    }

    // Use this event to track when users click items in the search results. If you’re using Algolia to build your category pages, you’ll also use the clickedObjectIDsAfterSearch event.
    // Details https://www.algolia.com/doc/api-reference/api-methods/clicked-object-ids-after-search/
    public async logAlgoliaClickedObjectIDsAfterSearchEvent(
        index: string,
        //Algolia can only use a limit amount of signals for training its recommendation/ranking model. Therefore, we should use a limited amount of event names.
        eventName: "Click Project" | "Click Component" | "Click Account",
        queryID: string,
        objectIDs: string[],
        positions: number[],
    ) {
        if (!(await this.isAnalyticsEnabled(this.currentUserService.getCurrentUser()))) {
            return;
        }

        AlgoliaTrack("clickedObjectIDsAfterSearch", this.currentUserService.getCurrentUser()?.uid, index, eventName, {
            queryID,
            objectIDs,
            positions,
        });
    }

    // Use this event to track when users click items unrelated to a previous Algolia request. For example, if you don’t use Algolia to build your category pages, use clickedObjectIDs.
    // Details https://www.algolia.com/doc/api-reference/api-methods/clicked-object-ids/
    public async logAlgoliaClickedObjectIDsEvent(
        index: string,
        //Algolia can only use a limit amount of signals for training its recommendation/ranking model. Therefore, we should use a limited amount of event names.
        eventName: "Click Project" | "Click Component" | "Click Account",
        objectIDs: string[],
    ) {
        if (!(await this.isAnalyticsEnabled(this.currentUserService.getCurrentUser()))) {
            return;
        }

        AlgoliaTrack("clickedObjectIDs", this.currentUserService.getCurrentUser()?.uid, index, eventName, {objectIDs});
    }

    // Use this event to track when users convert after a previous Algolia request. For example, a user clicks on an item in the search results to view the product detail page. Then, the user adds the item to their shopping cart.
    // Details https://www.algolia.com/doc/api-reference/api-methods/converted-object-ids-after-search/
    public async logAlgoliaConvertedObjectIDsAfterSearchEvent(
        index: string,
        //Algolia can only use a limit amount of signals for training its recommendation/ranking model. Therefore, we should use a limited amount of event names.
        eventName:
            | "Click Project"
            | "Click Component"
            | "Click Account"
            | "Follow Account"
            | "Star Project"
            | "Star Component"
            | "Add Component to Project"
            | "Use Project Template",
        queryID: string,
        objectIDs: string[],
    ) {
        if (!(await this.isAnalyticsEnabled(this.currentUserService.getCurrentUser()))) {
            return;
        }

        AlgoliaTrack("convertedObjectIDsAfterSearch", this.currentUserService.getCurrentUser()?.uid, index, eventName, {
            queryID,
            objectIDs,
        });
    }

    // Use this event to track when users convert on items unrelated to a previous Algolia request. For example, if you don’t use Algolia to build your category pages, use convertedObjectIDs.
    // Details https://www.algolia.com/doc/api-reference/api-methods/converted-object-ids/
    public async logAlgoliaConvertedObjectIDsEvent(
        index: string,
        //Algolia can only use a limit amount of signals for training its recommendation/ranking model. Therefore, we should use a limited amount of event names.
        eventName: "Follow Account" | "Star Project" | "Star Component",
        objectIDs: string[],
    ) {
        if (!(await this.isAnalyticsEnabled(this.currentUserService.getCurrentUser()))) {
            return;
        }

        AlgoliaTrack("convertedObjectIDs", this.currentUserService.getCurrentUser()?.uid, index, eventName, {
            objectIDs,
        });
    }

    // Send a view event to capture viewed items. Sending this event is useful for: Personalization
    // Details https://www.algolia.com/doc/api-reference/api-methods/viewed-object-ids/
    public async logAlgoliaViewedObjectIDsEvent(
        index: string,
        //Algolia can only use a limit amount of signals for training its recommendation/ranking model. Therefore, we should use a limited amount of event names.
        eventName: "View Project" | "View Component" | "View Account",
        objectIDs: string[],
    ) {
        if (!(await this.isAnalyticsEnabled(this.currentUserService.getCurrentUser()))) {
            return;
        }

        AlgoliaTrack("viewedObjectIDs", this.currentUserService.getCurrentUser()?.uid, index, eventName, {
            objectIDs,
        });
    }

    async isAnalyticsEnabled(currentUser: IUserData | null | undefined) {
        return (
            // services such as segment, amplitude, etc are moving to MTU
            // billing models, so paying for bot "users" is expensive
            !currentAgentIsBot &&
            // only send analytics from environments we care about (e.g., prod, release)
            isAnalyticsEnabledEnvironment() &&
            // we don't care about test analytics, so ignore test users
            !isTestAccount(currentUser, "loose") &&
            // finally, fallback to filtering out known ignored IPs (ahrefs)
            !(await this.isIpBlocked())
        );
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public async segmentIdentify(userUid: UserUid, userMetadata?: UserTraits, options?: {}) {
        await this.attemptWithFailureLogging(userUid, async () => {
            // see https://segment.com/docs/connections/spec/identify/
            await this.getSegment().identify(userUid, userMetadata, defaultSegmentOptions(options));
        });
    }

    private trackEventMetaData(eventParameters: {}) {
        eventParameters = this.setAppVersion(eventParameters);
        eventParameters = this.trackCurrentUrl(eventParameters);
        eventParameters = this.trackUTMParams(eventParameters);
        eventParameters = this.trackReferrerUTMParams(eventParameters);
        eventParameters = this.trackPlatform(eventParameters);
        // TODO: remove this eventually once we're confident bots aren't being tracked anymore
        eventParameters = this.trackIsBot(eventParameters);
        eventParameters = this.trackReferrer(eventParameters);
        eventParameters = this.trackPerf(eventParameters);
        eventParameters = this.trackTimestamp(eventParameters);
        eventParameters = this.trackScreenAndWindowSize(eventParameters);
        eventParameters = this.trackEnvironment(eventParameters);
        eventParameters = this.trackEditorMode(eventParameters);
        eventParameters = this.trackRenderStats(eventParameters);
        eventParameters = this.trackDocumentStats(eventParameters);
        eventParameters = this.trackDocumentViaStats(eventParameters);
        eventParameters = this.trackPcbPerfStats(eventParameters);

        return eventParameters;
    }

    private setAppVersion(eventParameters: {}) {
        return {...eventParameters, appVersion: APP_VERSION};
    }

    private trackEnvironment(eventParameters: {}) {
        eventParameters = {
            ...eventParameters,
            environment_type: getEnvironmentName(),
            hosting_provider: process.env.REACT_APP_HOST,
        };
        return eventParameters;
    }

    private trackTimestamp(eventParameters: {}) {
        eventParameters = {
            ...eventParameters,
            client_timestamp: Date.now(),
        };
        return eventParameters;
    }

    private trackScreenAndWindowSize(eventParameters: {}) {
        eventParameters = {
            ...eventParameters,
            screen_resolution: `${window.screen.width}x${window.screen.height}`,
            native_screen_resolution: `${window.screen.width * window.devicePixelRatio}x${
                window.screen.height * window.devicePixelRatio
            }`,
            device_pixel_ratio: window.devicePixelRatio,
            inner_window_resolution: `${window.innerWidth}x${window.innerHeight}`,
        };
        return eventParameters;
    }

    private trackPerf(eventParameters: {}) {
        // @ts-ignore exists in chrome
        const memory = window.performance.memory;
        if (memory) {
            const {jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize} = memory;

            eventParameters = {
                ...eventParameters,
                jsHeapSizeLimit,
                totalJSHeapSize,
                usedJSHeapSize,
            };

            this.checkMemUsage(usedJSHeapSize);
        }
        return eventParameters;
    }

    // QUESTION: make this relative to jsHeapSizeLimit?
    private checkMemUsage(usedJSHeapSize: number) {
        const memBudgetInMB = 2000;
        const usedJSHeapSizeInMB = usedJSHeapSize && Math.round(usedJSHeapSize / 1000 / 1000);
        if (usedJSHeapSizeInMB && usedJSHeapSizeInMB > memBudgetInMB * 2) {
            FluxLogger.captureError(
                new PerformanceBudgetViolationError(
                    usedJSHeapSizeInMB,
                    memBudgetInMB * 2,
                    " MB memory, double the budget!",
                    "checkMemUsage 2X",
                ),
            );
        } else if (usedJSHeapSizeInMB && usedJSHeapSizeInMB > memBudgetInMB) {
            FluxLogger.captureError(
                new PerformanceBudgetViolationError(usedJSHeapSizeInMB, memBudgetInMB, " MB memory", "checkMemUsage"),
            );
        }
    }

    private trackIsBot<T>(eventParameters: T) {
        eventParameters = {
            ...eventParameters,
            user_agent: navigator?.userAgent,
            is_bot: String(currentAgentIsBot),
        };
        return eventParameters;
    }

    private trackUTMParams(eventParameters: {}) {
        const utm = new Utm(window.location.href);
        const values = utm.get();

        eventParameters = {
            ...eventParameters,
            ...values,
        };
        return eventParameters;
    }

    /**
     * Adds Facebook browser ID, unformatted fbclid, and formatted fbclid to the event parameters passed in.
     */
    private trackFacebookClickIds(eventParameters: TrackingEventParameters & UserTraits) {
        const ids: Record<string, string> = {};
        // Docs: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
        // The FB pixel stores a formatted fbclid (_fbc), and browserid (_fbp) in cookies. These can change, or the pixel could be blocked, so dont trust it.
        // - The query param fbclid is guaranteed to be associated with an ad though and easy to attribute to a conversion so use that.
        // - The fbclid is unformatted, so we must format it before reporting it and the fbp.
        // - The _fbc is not reported because its unreliable for our purposes. Ad blockers, slow connections, etc can cause this to be out of sync with the actual fbclid in the URL
        const fbp = Cookie.get("_fbp");
        const fbclid = getQueryParam("fbclid");

        if (fbp) {
            ids.fbp = fbp;
        }

        if (fbclid) {
            /**
             *  Required format: <version>.<subdomainIndex>.<creationTime>.<fbclid> (actual test value:
             * fb.1.1738346926908.test123)
             *  - version is always fb
             *  - subdomainIndex is which domain the cookie is defined on ('com' = 0, 'abc.com' = 1, 'www.abc.com' = 2;
             * local testing shows 1 always being used)
             *  - creationTime is the UNIX time since epoch in milliseconds when the _fbc was stored.
             */
            const fbclid_formatted = `fb.1.${Date.now()}.${fbclid}`;
            ids.fbclid = fbclid;
            ids.fbclid_formatted = fbclid_formatted;
        }

        return {...eventParameters, ...ids};
    }

    private trackGoogleClickIds(eventParameters: TrackingEventParameters & UserTraits) {
        const params = new URLSearchParams(window.location.search);
        // these are also captured in a similar way on the marketing and docs sites
        const paramsToReport = ["gclid", "dclid"];

        const relevantIds = [...params.entries()]
            .filter(([key]) => paramsToReport.includes(key) || key.startsWith("gad"))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {} as Record<string, string>);

        return {...eventParameters, ...relevantIds};
    }

    // Most utm params are stripped from links from facebook, google, etc.,  but are still available on the referrer. We can use these to track attribution
    private trackReferrerUTMParams(eventParameters: {}) {
        if (document.referrer) {
            const utm = new Utm(document.referrer);
            const values = utm.get();
            const prefixedValues = Object.keys(values).reduce(
                (a: any, c) => (
                    // prefix utm params with referrer_ to avoid collision with current page utm params
                    (a[`referrer_${c}`] = values[c]), a
                ),
                {},
            );

            eventParameters = {
                ...eventParameters,
                ...prefixedValues,
            };
        }
        return eventParameters;
    }

    private trackCurrentUrl(eventParameters: {}) {
        // via https://stackoverflow.com/questions/5004978/check-if-page-gets-reloaded-or-refreshed-in-javascript
        const pageAccessedByReload =
            (window.performance.navigation &&
                window.performance.navigation.type === performance.navigation.TYPE_RELOAD) ||
            window.performance
                .getEntriesByType("navigation")
                .map((nav) => nav.entryType)
                .includes("reload");

        eventParameters = {
            ...eventParameters,
            current_url: window.location.href,
            page_accessed_by_reload: pageAccessedByReload,
        };
        return eventParameters;
    }

    private trackPlatform<T>(eventParameters: T) {
        if (navigator?.platform) {
            eventParameters = {
                ...eventParameters,
                platform: navigator.platform,
            };
        }

        return eventParameters;
    }

    private trackReferrer(eventParameters: {}) {
        if (document.referrer) {
            eventParameters = {
                ...eventParameters,
                referrer: document.referrer,
            };
        }
        return eventParameters;
    }

    private async identifyUser() {
        const userData = this.currentUserService.getCurrentUser();

        if (userData) {
            const userMetaData = this.getUserMetaData(userData);
            await this.segmentIdentify(userData.uid, userMetaData);

            // we save certain analytics metadata for better attributing frontend & backend events and user journeys
            await this.saveAnalyticsMetadata(userData.uid);

            // Don't wait for this, because it needs to do an additional query
            // TODO: use the currentOrganizationMembershipsService once available (WIP: Dom)
            void this.segmentGroup(userData.uid);
        }
    }

    private getUserMetaData(userData: IUserData): UserTraits & TrackingEventParameters {
        const userMetadata = this.currentUserService.getCurrentUserPrivateMetadata() ?? {uid: userData.uid};
        const featureFlags = this.getFeatureFlags();

        const plan_epoch = getPlanEpochFromUserPrivateMetadata(userMetadata) ?? PlanEpoch.enum.legacy;
        const trial_type = getTrialTypeFromUserPrivateMetadata(userMetadata) ?? "unknown";
        const migrated_from_plan_epoch = userMetadata.creationFlags?.migratedFromPlanEpoch ?? "none";

        // Segment flattens objects to dot notation column names, so ignore objects (or transform to a primitive type).
        // Otherwise, we quickly hit SQL column limits in the User and Identify tables due to uid properties on objects (ex: project_templates)
        let userMetaData: TrackingEventParameters & UserTraits = {
            ...featureFlags,
            // Don't spread the userData object! Be explicit about what is sent to Segment, and only send primitive types
            activeProductUid: userData.activeProductUid,
            created_at: userData.created_at, // TODO: createdAt is a standard Segment trait name, created_at is not!
            custom_picture_key: userData.custom_picture_key,
            email: userData.email,
            full_name: userData.full_name,
            github_handle: userData.github_handle,
            handle: userData.handle,
            hearAboutFlux: userMetadata?.personal?.hearAboutFlux,
            isAnonymous: userData.isAnonymous,
            last_active_at: userData.last_active_at,
            last_sign_in_at: userData.last_sign_in_at,
            // IMPORTANT: full name doesn't always exist, so use handle as a fallback so that downstream destinations don't reject the event
            name: userData.full_name || userData.handle,
            picture: userData.picture,
            primaryUse: userMetadata?.personal?.primaryUse,
            role: userMetadata?.personal?.role,
            sign_up_page_referrer: userData.sign_up_page_referrer,
            sign_up_referrer: userData.sign_up_referrer,
            star_count: userData.star_count,
            tag_line: userData.tag_line,
            theme: userData.theme,
            twitter_handle: userData.twitter_handle,
            uid: userData.uid,
            updated_at: userData.updated_at,
            website_url: userData.website_url,
            privateProjectsCount: userMetadata?.projects?.privateProjectCount,
            documents_count: userData.documents_count,
            parts_count: userData.parts_count,
            follower_count: userData.follower_count,
            following_count: userData.following_count,
            plan_epoch,
            trial_type,
            migrated_from_plan_epoch,
            trial_state: getStateOfTrialSubscription(getTrialInfoFromUserPrivateMetadata(userMetadata)),
            onboarding_flow: userMetadata?.onboarding?.flow ?? OnboardingFlow.enum.standard,

            /*
             * Override the feature flag value for the segmentOnboardingFlowStreamlinedExperiment feature flag to
             * "off" if the user is in the legacy plan epoch, because in this state the user will not receive the
             * streamlined onboarding experience (this is mainly to cover off legacy educational users, who need to go
             * through the full flow, or else they'll not be able to choose the educational plan)
             *
             * It would be probably better to mark such users as educational immediately, as their equivalent to the
             * streamlined flow. But we can deal with that after the experiment is over.
             */
            feature_flag_segmentOnboardingFlowStreamlinedExperiment:
                plan_epoch === PlanEpoch.enum.legacy
                    ? ExperimentVariant.enum.off
                    : experimentVariantFromConfiguration(
                          userMetadata.uid,
                          this.featureFlagsStore.getState().segmentOnboardingFlowStreamlinedExperiment ?? {
                              kind: ExperimentConfigurationKind.enum.off,
                          },
                      ),
        };

        userMetaData = this.trackPlatform(userMetaData);
        userMetaData = this.trackIsBot(userMetaData);
        userMetaData = this.trackGoogleClickIds(userMetaData);
        userMetaData = this.trackFacebookClickIds(userMetaData);

        return userMetaData;
    }

    private isTrackingEventPrimitiveParameterValue(
        featureFlagValue: unknown,
    ): featureFlagValue is TrackingEventPrimitiveParameterValue {
        return (
            typeof featureFlagValue === "boolean" ||
            typeof featureFlagValue === "string" ||
            typeof featureFlagValue === "number" ||
            typeof featureFlagValue === "undefined"
        );
    }

    /**
     * We don't want to automatically track all feature flags, because we're worried about the maximum number of
     * columns available when processing analytics events downstream (e.g. in Redshift)
     *
     * We track any flag beginning with 'segment' or 'ab'. Feature flag values must also be a primitive type, or an
     * object (which we will stringify as a JSON string)
     */
    private getFeatureFlags() {
        const currentFeatureFlags = this.featureFlagsStore.getState();
        const trackedFlags = trackedFlagsSchema.parse(currentFeatureFlags.trackedFlags);

        return Object.entries(currentFeatureFlags).reduce((acc: TrackingEventParameters, [key, value]) => {
            let trackingName: string | undefined;

            if (trackedFlags[key]) {
                trackingName = `features.${trackedFlags[key]}`;
            } else if (key.startsWith("segment") || key.startsWith("ab")) {
                trackingName = `feature_flag_${key}`;
            }

            if (trackingName) {
                if (typeof value === "object" || typeof value === "undefined") {
                    acc[trackingName] = JSON.stringify(value);
                } else if (this.isTrackingEventPrimitiveParameterValue(value)) {
                    acc[trackingName] = value;
                }
            }
            return acc;
        }, {});
    }

    private trackEditorMode(eventParameters: {}) {
        // it's possible for these to be entirely unset, therefore != null instead of !== undefined

        if (this.persistedDocumentUiStore.getState().editorMode != null) {
            eventParameters = {
                ...eventParameters,
                editorMode: this.persistedDocumentUiStore.getState().editorMode,
            };
        }

        if (usePcbEditorUiStore?.getState()?.cameraMode != null) {
            eventParameters = {
                ...eventParameters,
                cameraMode: usePcbEditorUiStore?.getState()?.cameraMode,
            };
        }

        if (this.storeService.getStore().getState().auth.currentUserHasEditPermission != null) {
            eventParameters = {
                ...eventParameters,
                currentUserHasEditPermission: this.storeService.getStore().getState().auth.currentUserHasEditPermission,
            };
        }

        if (this.storeService.getStore().getState().auth.currentUserHasCommentingPermission != null) {
            eventParameters = {
                ...eventParameters,
                currentUserHasCommentingPermission: this.storeService.getStore().getState().auth
                    .currentUserHasCommentingPermission,
            };
        }

        return eventParameters;
    }

    private trackRenderStats(eventParameters: {}) {
        if (this.persistedDocumentUiStore.getState().editorMode === EditorModes.pcb) {
            eventParameters = {
                ...eventParameters,
                ...pcbModeStats.perfSnapshot,
                ...inMemoryCacheStats,
                timeToFirstFramesInMs: pcbModeStats.timeToFirstFramesInMs,
            };
        } else if (this.persistedDocumentUiStore.getState().editorMode === EditorModes.schematic) {
            const {avgTimePerFrameInSeconds, avgFramesPerSecond, timeToFirstFramesInMs} = schematicModeStats;
            eventParameters = {
                ...eventParameters,
                avgTimePerFrameInSeconds,
                avgFramesPerSecond,
                webGlDrawCalls: schematicModeStats.drawCalls,
                timeToFirstFramesInMs,
                ...inMemoryCacheStats,
            };
        }

        return eventParameters;
    }

    private trackDocumentStats(eventParameters: {}) {
        const documentState = this.storeService.getStore().getState().document;

        if (documentState) {
            eventParameters = {
                ...eventParameters,
                document_uid: documentState.uid,
                document_name: documentState.name,
                document_has_custom_name: documentState.has_custom_name,
                document_description: documentState.description,
                document_archived: documentState.archived,
                document_is_public: documentState.has_anon_access,
                document_owner_uid: documentState.owner_uid,
                document_slug: documentState.slug,
                document_copy_of_document_uid: documentState.copy_of_document_uid,
                document_fork_of_document_uid: documentState.fork_of_document_uid,
                document_belongs_to_part_uid: documentState.belongs_to_part_uid,
                document_is_published: documentIsPublished(documentState),
                document_active_users_count: Object.keys(documentState.active_users).length,
                document_roles_count: Object.keys(documentState.roles).length,
                document_elements_count: Object.keys(documentState.elements).length,
                document_unique_parts_count: [
                    ...new Set(Object.values<IElementData>(documentState.elements).map((value) => value.part_uid)),
                ].length,
                document_routes_count: Object.keys(documentState.routes).length,
                document_assets_count: Object.keys(documentState.assets).length,
                document_footprint_assets_count: Object.values<IAssetData>(documentState.assets).filter(
                    (asset) => asset.isFootprint,
                ).length,
                document_3d_model_assets_count: Object.values<IAssetData>(documentState.assets).filter(
                    (asset) => asset.is3dModel,
                ).length,
                document_pcb_board_shape_assets_count: Object.values<IAssetData>(documentState.assets).filter(
                    (asset) => asset.isPcbBoardShape,
                ).length,
                document_pcbLayoutNodes_count: Object.values(documentState.pcbLayoutNodes).length,
                document_pcbLayoutRuleSets_count: Object.keys(documentState.pcbLayoutRuleSets).length,
                document_configs_count: Object.keys(documentState.configs).length,
                document_controls_count: (documentState.controls || []).length,
                document_properties_count: Object.keys(documentState.properties || {}).length,
                document_contributors_count: (documentState.contributor_uids || []).length,
                document_comment_threads_count: Object.keys(documentState.comment_threads).length,
                document_comments_count: documentState.comment_count,
                document_comment_likes_count: documentState.comment_likes_count,
                document_copy_count: documentState.copy_count,
                document_fork_count: documentState.fork_count,
            };
        }

        return eventParameters;
    }

    private trackPcbPerfStats(eventParameters: {}) {
        const bakedPcbLayoutNodes = this.documentService.snapshot().pcbLayoutNodes;

        if (bakedPcbLayoutNodes) {
            const pcbLayoutNodes = Object.values(bakedPcbLayoutNodes);
            eventParameters = {
                ...eventParameters,
                // For discussion of definitions, see
                // [workplace-post/3021695821474113](/docs/workplace/3021695821474113.md#3026772254299803)
                perf_component_count: pcbLayoutNodes.filter(
                    (node) => node.type === PcbNodeTypes.footprint && node.bakedRules?.active,
                ).length,
                perf_node_count: pcbLayoutNodes.filter((node) => node.bakedRules?.active).length,
                perf_layout_count: pcbLayoutNodes.filter(
                    (node) => node.type === PcbNodeTypes.layout && node.bakedRules?.active,
                ).length,
            };
        }

        return eventParameters;
    }

    private trackDocumentViaStats(eventParameters: {}) {
        const bakedPcbLayoutNodes = this.documentService.snapshot().pcbLayoutNodes;
        if (!bakedPcbLayoutNodes) {
            return eventParameters;
        }

        const viasByTypes = Object.values(PcbViaType).reduce((acc, type) => {
            acc[type] = 0;
            return acc;
        }, {} as Record<PcbViaType, number>);

        const stackupInfoCache = new Map<string, StackupInfo>();
        const viaTypeCache = new Map<string, PcbViaType>();

        for (const node of Object.values(bakedPcbLayoutNodes || {})) {
            if (node.bakedRules.active && node.type === PcbNodeTypes.via) {
                const bakedViaType = node.bakedRules.viaType;
                let resolvedViaType = viaTypeCache.get(bakedViaType);

                if (!resolvedViaType) {
                    const {viaConfigs, copperLayers} = getStackupLayersAndViaConfigs(
                        node.bakedRules,
                        bakedPcbLayoutNodes,
                        stackupInfoCache,
                    );
                    const segmentInfo = getViaSegmentFromType(bakedViaType, viaConfigs);
                    if (segmentInfo) {
                        resolvedViaType = getViaSegmentCanonicalType(segmentInfo.segment, copperLayers);
                        viaTypeCache.set(bakedViaType, resolvedViaType);
                    }
                }

                if (resolvedViaType) {
                    viasByTypes[resolvedViaType]++;
                }
            }
        }

        const viaCountParameters: Record<string, number> = {};
        for (const [key, value] of Object.entries(viasByTypes)) {
            viaCountParameters[`document_via_${key}_count`] = value;
        }

        eventParameters = {
            ...eventParameters,
            ...viaCountParameters,
        };

        return eventParameters;
    }

    private async isIpBlocked() {
        if (!this.ipAddress) {
            this.ipAddress = await getIpAddress();
        }

        return isIpAddressBlocked(this.ipAddress);
    }

    private async saveAnalyticsMetadata(userId: UserUid) {
        await this.userAnalyticsRepository.saveUserAnalyticsMetadata(userId, {
            facebookBrowserId: Cookie.get("_fbp"),
            facebookClickId: Cookie.get("_fbc"),
            ipAddress: this.ipAddress,
            userAgent: navigator.userAgent,
        });
    }

    private getSegment() {
        if (!this.segment) {
            this.segment = AnalyticsBrowser.load(
                {
                    writeKey: "0gUw8uXwVwNxdX13rZjAV7PGe2Nb20jM",
                    // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/#custom-cdn--api-proxy
                    cdnURL: "https://events-cdn.flux.ai",
                },
                {
                    // See [workplace-post/2915464885430541](/docs/workplace/2915464885430541.md)
                    // and https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#batching
                    integrations: {
                        "Segment.io": {
                            deliveryStrategy: {
                                strategy: "batching",
                                config: {
                                    size: 10,
                                    timeout: 5000,
                                },
                            },
                            // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/#custom-cdn--api-proxy
                            apiHost: "events-api.flux.ai/v1",
                            protocol: "https",
                        },
                    },
                    obfuscate: true,
                },
            );
        }
        return this.segment;
    }

    private async attemptWithFailureLogging(userId: UserUid, inner: () => Promise<void>): Promise<void> {
        try {
            return await inner();
        } catch (err: unknown) {
            // when calls to identify/analytics fail, we're assuming that calls to Sentry and similar will also fail for the same reasons (ad blockers, VPN, etc)
            // we need the ability to measure how often this happens so that we can confirm the follow-up proxying of analytics unblocks tracking of these users
            if (!this.trackingErrorLogged) {
                this.trackingErrorLogged = true;
                await this.userAnalyticsRepository.incrementUserAnalyticsIdentifyErrorCount(userId);
            }
        }
    }

    private async segmentGroup(userUid: UserUid): Promise<void> {
        const orgStoreState = this.useOrganizationStore.getState();

        // This logic would have been done for us by useCurrentPageOrganizationContext() + useCurrentOrganizationMembershipForOrganization(organizationUidFromContext),
        // but we're not in a react component, so we have to do it ourselves for now
        const currentOrganizationContextUid =
            this.storeService.getStore().getState().document?.organization_owner_uid ??
            orgStoreState.currentPageOrganizationContext;

        // TODO: move subscribing to current organizations membership state to a service (WIP: Dom), like it is with currentUser
        const membership = orgStoreState.memberships.find((m) => m.organizationUid === currentOrganizationContextUid);

        if (!orgStoreState.finishedLoading || !currentOrganizationContextUid || !membership) {
            return;
        }

        await this.attemptWithFailureLogging(userUid, async () => {
            // see https://segment.com/docs/connections/spec/group/
            // see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#group
            // NOTE: The browser SDK remembers the last identify call, and associates this group call with that user - that's why
            // there's no user ID in this call
            await this.getSegment().group(
                membership.organizationUid,
                traitsForOrganizationAsGroup(membership.organization),
                defaultSegmentOptions(),
            );
        });
    }

    private segmentTrack = (event: string, properties?: {}, options?: {}) => {
        return this.getSegment().track(event, properties, defaultSegmentOptions(options));
    };

    private segmentPage = (category?: string, name?: string, properties?: {}, options?: {}) => {
        return this.getSegment().page(category, name, properties, defaultSegmentOptions(options));
    };
}
