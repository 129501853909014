import {EditorModes, getTopLevelLayouts} from "@buildwithflux/core";
import {matchLayerIdentifier} from "@buildwithflux/models";

import type {IDrcInputs, IDrcValidator} from "../types";

export class InvalidLayerValidator implements IDrcValidator {
    problemTypeKey = "invalid_layer";
    problemLabel = "Invalid Layer";
    problemDescription = "Reports nodes that are assigned to a layer that doesn't exist in the layout stackup.";

    async checkForProblems({pcbLayoutNodes}: IDrcInputs) {
        const layouts = getTopLevelLayouts(pcbLayoutNodes);
        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: layouts.flatMap((layout) => {
                const stackup = layout?.bakedRules?.stackup ?? {};

                const stackupValues = Object.values(stackup);
                const nodesWithInvalidLayerUids: string[] = [];
                for (const uid in pcbLayoutNodes ?? {}) {
                    const foundLayer = stackupValues.find((layer) => {
                        return matchLayerIdentifier(layer, pcbLayoutNodes[uid]!.bakedRules?.layer);
                    });

                    if (!foundLayer) {
                        nodesWithInvalidLayerUids.push(uid);
                    }
                }

                return nodesWithInvalidLayerUids.map((uid) => ({
                    problemTypeKey: this.problemTypeKey,
                    key: `${this.problemTypeKey}_${uid}`,
                    affectedItems: [{type: "pcbLayoutNode" as const, uid}],
                    affectedViews: [EditorModes.pcb],
                }));
            }),
        };
    }
}
