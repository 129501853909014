/**
 * Creates a bounded queue that limits the number of tasks that can be enqueued at a given time, to two items.
 * Enqueued tasks are executed sequentially. If new tasks are added while an enqueued task is waiting to be processed,
 * the item that is waiting to be processed will be overridden by the next enqueued task.
 */
export function createBoundedQueue() {
    let tasks: Array<() => Promise<unknown>> = [];

    const enqueue = (task: (() => Promise<unknown>) | undefined) => {
        if (!task) {
            return;
        }

        // If queue non empty then we assume a queue processor is active
        const shouldStartQueueProcessor = tasks.length === 0;

        // Only allow one additional task at given time
        const currentTask = tasks.at(0);
        if (currentTask) {
            // This is in essence, what makes this a bounded queue. Prevents
            // more than two items being queued, discards any item that was
            // awaiting execution
            tasks = [currentTask, task];
        } else {
            tasks = [task];
        }

        if (shouldStartQueueProcessor) {
            (async () => {
                // TODO: move ui progress start here
                while (tasks.length > 0) {
                    const peekTask = tasks.at(0);
                    if (peekTask) {
                        await peekTask();
                    }
                    // Actually remove the current task from the queue
                    tasks.shift();
                }

                // TODO: move ui complete here
            })();
        }
    };

    const clear = () => {
        tasks = [];
    };

    return {
        enqueue,
        clear,
    };
}
