import {PcbNodeTypes, EditorModes, PcbBakedNode, getNodesOfType, filterTruthy} from "@buildwithflux/core";
import {circleCircle, polygonCircle, polygonPolygon} from "intersects";
import lineToPolygon from "intersects/lineToPolygon";
import {groupBy} from "lodash";
import RBush from "rbush";
import {Euler} from "three";

import {eulerFromXYZ, vector3FromXYZ} from "../../../helpers/ThreeJSHelper";
import type {IDrcInputs, IDrcValidator} from "../types";

// DEPRECATED: To be removed in favor of the new OverlappingCopperValidator

// IMPORTANT: This algorithm works in 2D only and it only finds the collisions on the XY plane projection

const zeroEuler = new Euler(0, 0, 0);

export class OverlappingTracesValidator implements IDrcValidator {
    problemTypeKey = "overlapping_traces";
    problemLabel = "Overlapping Traces";
    problemDescription =
        "Reports traces that are shorting. NOTE: only actually overlapping traces are considered. Overlapping pads, vias or spacing problems will not be reported.";

    async checkForProblems({pcbLayoutNodes}: IDrcInputs) {
        const allRouteSegments = getNodesOfType(pcbLayoutNodes, PcbNodeTypes.routeSegment);
        const segmentsPerLayer = groupBy(allRouteSegments, "bakedRules.layer");
        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: Object.values(segmentsPerLayer).flatMap((segments) =>
                this.detectCollisionProblemsInRouteSegments(segments),
            ),
        };
    }

    // This function finds all collisions regardless of the layers. The splitting into layers is done in checkForProblems.
    private detectCollisionProblemsInRouteSegments(routeSegments: PcbBakedNode<PcbNodeTypes.routeSegment>[]) {
        // First of all, let's get some info for all our segments
        const routeSegmentInfos = filterTruthy(
            routeSegments.map((rs) => {
                const absolutePos = vector3FromXYZ(rs.bakedRules?.positionRelativeToDocument);
                const absoluteRot = eulerFromXYZ(rs.bakedRules?.rotationRelativeToDocument ?? zeroEuler);
                const start = vector3FromXYZ(rs.bakedRules?.startPosition);
                const end = vector3FromXYZ(rs.bakedRules?.endPosition);
                const size = vector3FromXYZ(rs.bakedRules?.size);
                const hasAllInfo = absolutePos && start && end && size;
                if (!hasAllInfo) return null;

                const width = size.x;
                const rad = width / 2;
                const absStart = start.applyEuler(absoluteRot).add(absolutePos);
                const absEnd = end.applyEuler(absoluteRot).add(absolutePos);

                // We can view a trace as a polygon (a rotated rectangle) and two circles
                // The return value is an array the four points: [x0,y0,x1,y1,x2,y2,x3,y3]
                const poly = lineToPolygon(absStart.x, absStart.y, absEnd.x, absEnd.y, width);

                // We over approximate the BB with the cap radius
                const minX = Math.min(poly[0], poly[2]) - width;
                const minY = Math.min(poly[1], poly[3]) - width;
                const maxX = Math.max(poly[0], poly[2]) + width;
                const maxY = Math.max(poly[1], poly[3]) + width;

                const netId = rs.bakedRules.hostNetId;

                return {
                    uid: rs.uid,
                    netId,
                    width,
                    rad,
                    absStart,
                    absEnd,
                    poly,
                    minX,
                    minY,
                    maxX,
                    maxY,
                };
            }),
        );

        // We build a tree to search collisions more rapidly, turning from N^2 to NlogN
        const tree = new RBush();
        tree.load(routeSegmentInfos);

        // For each route segment, we try to all the segments it collides with, and we filter out the ones without any collision
        const collisions = routeSegmentInfos
            .map((rsA) => ({
                segment: rsA,
                collidesWith: (tree.search(rsA) as typeof routeSegmentInfos).filter((rsB) => {
                    // A collision with itself or with the same net is not a collision
                    const notTheSame = rsA.uid !== rsB.uid;
                    const differentNet = rsA.netId !== rsB.netId;
                    if (!notTheSame || !differentNet) return false;

                    //
                    // Line body vs line body
                    //
                    const lineLineCollision = polygonPolygon(rsA.poly, rsB.poly);
                    if (lineLineCollision) return true;

                    //
                    // Cap vs line body
                    //
                    const capA1LineBCollision = polygonCircle(
                        rsB.poly,
                        rsA.absStart.x,
                        rsA.absStart.y,
                        rsA.rad,
                        Number.EPSILON,
                    );
                    if (capA1LineBCollision) return true;
                    const capA2LineBCollision = polygonCircle(
                        rsB.poly,
                        rsA.absEnd.x,
                        rsA.absEnd.y,
                        rsA.rad,
                        Number.EPSILON,
                    );
                    if (capA2LineBCollision) return true;
                    const capB1LineACollision = polygonCircle(
                        rsA.poly,
                        rsB.absStart.x,
                        rsB.absStart.y,
                        rsB.rad,
                        Number.EPSILON,
                    );
                    if (capB1LineACollision) return true;
                    const capB2LineACollision = polygonCircle(
                        rsA.poly,
                        rsB.absEnd.x,
                        rsB.absEnd.y,
                        rsB.rad,
                        Number.EPSILON,
                    );
                    if (capB2LineACollision) return true;

                    //
                    // Cap vs cap
                    //
                    const capA1CapB1Collision = circleCircle(
                        rsA.absStart.x,
                        rsA.absStart.y,
                        rsA.rad,
                        rsB.absStart.x,
                        rsB.absStart.y,
                        rsB.rad,
                    );
                    if (capA1CapB1Collision) return true;
                    const capA1CapB2Collision = circleCircle(
                        rsA.absStart.x,
                        rsA.absStart.y,
                        rsA.rad,
                        rsB.absEnd.x,
                        rsB.absEnd.y,
                        rsB.rad,
                    );
                    if (capA1CapB2Collision) return true;
                    const capA2CapB1Collision = circleCircle(
                        rsA.absEnd.x,
                        rsA.absEnd.y,
                        rsA.rad,
                        rsB.absStart.x,
                        rsB.absStart.y,
                        rsB.rad,
                    );
                    if (capA2CapB1Collision) return true;
                    const capA2CapB2Collision = circleCircle(
                        rsA.absEnd.x,
                        rsA.absEnd.y,
                        rsA.rad,
                        rsB.absEnd.x,
                        rsB.absEnd.y,
                        rsB.rad,
                    );
                    if (capA2CapB2Collision) return true;

                    return false;
                }),
            }))
            .filter((coll) => coll.collidesWith.length !== 0);

        // We eliminate the duplicates this way, since every collision between A and B will produce a pair of AB/BA
        const collisionKeys = Array.from(
            new Set(
                collisions.flatMap((col) =>
                    col.collidesWith.map((colWith) => [col.segment.uid, colWith.uid].sort().join("+")),
                ),
            ),
        );

        return collisionKeys.map((ck) => ({
            problemTypeKey: this.problemTypeKey,
            key: `${this.problemTypeKey}_${ck}`,
            affectedItems: ck.split("+").map((uid) => ({type: "pcbLayoutNode" as const, uid})),
            affectedViews: [EditorModes.pcb],
        }));
    }
}
