import {
    AsyncClipperShapeFactory,
    PcbQuadTreeNode,
    Primitive,
    PrimitiveContainer,
    PrimitiveType,
} from "@buildwithflux/core";
import RBush from "rbush";
import {Matrix3, Matrix4} from "three";

import {IDrcInputs} from "../../types";

import {RawJson, SerializedInputs} from "./types";

export function serializeInputs(inputs: IDrcInputs) {
    const containerMap: Record<string, Omit<PrimitiveContainer, "quadTree"> & {quadTree: RawJson}> = {};

    for (const key of inputs.containerMap.keys()) {
        const container = inputs.containerMap.get(key);
        if (!container) {
            continue;
        }

        // We need to serialize the RBush data structure for portability to the worker
        containerMap[key] = {
            ...container,
            quadTree: container.quadTree.toJSON(),
        };
    }

    return {
        nodeShapesMap: inputs.nodeShapesMap, // Note: contains THREEJS Matrices that become element arrays
        pcbLayoutNodes: inputs.pcbLayoutNodes,
        containerMap,
    };
}

export async function deserializeInputs(inputs: SerializedInputs) {
    const clipperShapeFactory = await new AsyncClipperShapeFactory().load();

    // TODO: update primitives to not use THREEJS matrices
    const deserializeMatrix3 = (elements: number[]) => {
        return new Matrix3().fromArray(elements);
    };
    const deserializeMatrix4 = (elements: number[]) => {
        return new Matrix4().fromArray(elements);
    };
    const deserializePrimitive = (p: Primitive) => {
        return {
            ...p,
            transform: deserializeMatrix3(p.transform.elements),
            transformInverse: deserializeMatrix3(p.transformInverse.elements),
        };
    };

    for (const [, shapes] of inputs.nodeShapesMap) {
        for (const primitive of shapes.primitives) {
            deserializePrimitive(primitive);

            if (primitive.type === PrimitiveType.Difference) {
                deserializePrimitive(primitive.primitiveA);
                deserializePrimitive(primitive.primitiveB);
            }
            if (primitive.type === PrimitiveType.Union) {
                deserializePrimitive(primitive.primitiveA);
                deserializePrimitive(primitive.primitiveB);
            }
            if (primitive.type === PrimitiveType.Expansion) {
                deserializePrimitive(primitive.primitive);
            }
        }
    }

    const containerMap = new Map<string, PrimitiveContainer>();

    for (const key in inputs.containerMap) {
        const json = inputs.containerMap[key];
        if (!json) {
            throw new Error(`Invalid container ${key}`);
        }

        const primitiveContainer = {
            transform: deserializeMatrix4(json.transform.elements),
            transformInverse: deserializeMatrix4(json.transformInverse.elements),
            quadTree: new RBush<PcbQuadTreeNode>().fromJSON(json.quadTree),
        };

        containerMap.set(key, primitiveContainer);
    }

    return {
        nodeShapesMap: inputs.nodeShapesMap,
        pcbLayoutNodes: inputs.pcbLayoutNodes,
        containerMap,
        clipperShapeFactory,
    };
}
